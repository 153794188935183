.\!container {
    width: 100% !important;
}
.container {
    width: 100%;
}
@media (min-width: 640px) {
    .\!container {
        max-width: 640px !important;
    }
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .\!container {
        max-width: 768px !important;
    }
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .\!container {
        max-width: 1024px !important;
    }
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .\!container {
        max-width: 1280px !important;
    }
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .\!container {
        max-width: 1536px !important;
    }
    .container {
        max-width: 1536px;
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none {
    pointer-events: none;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.collapse {
    visibility: collapse;
}
.static {
    position: static;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0px;
}
.inset-y-0 {
    top: 0px;
    bottom: 0px;
}
.bottom-0 {
    bottom: 0px;
}
.bottom-6 {
    bottom: 1.5rem;
}
.left-0 {
    left: 0px;
}
.left-1 {
    left: 0.25rem;
}
.left-1\/2 {
    left: 50%;
}
.left-2 {
    left: 0.5rem;
}
.left-4 {
    left: 1rem;
}
.left-\[1px\] {
    left: 1px;
}
.left-\[7px\] {
    left: 7px;
}
.right-0 {
    right: 0px;
}
.right-1 {
    right: 0.25rem;
}
.right-1\/2 {
    right: 50%;
}
.right-2 {
    right: 0.5rem;
}
.right-5 {
    right: 1.25rem;
}
.right-6 {
    right: 1.5rem;
}
.top-0 {
    top: 0px;
}
.top-2 {
    top: 0.5rem;
}
.top-4 {
    top: 1rem;
}
.top-5 {
    top: 1.25rem;
}
.top-9 {
    top: 2.25rem;
}
.top-\[1px\] {
    top: 1px;
}
.top-\[4px\] {
    top: 4px;
}
.top-\[8px\] {
    top: 8px;
}
.top-full {
    top: 100%;
}
.-z-0 {
    z-index: 0;
}
.-z-10 {
    z-index: -10;
}
.z-0 {
    z-index: 0;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-50 {
    z-index: 50;
}
.z-\[10\] {
    z-index: 10;
}
.z-\[20\] {
    z-index: 20;
}
.z-\[2\] {
    z-index: 2;
}
.z-\[3\] {
    z-index: 3;
}
.z-\[4\] {
    z-index: 4;
}
.z-\[5\] {
    z-index: 5;
}
.z-\[9999\] {
    z-index: 9999;
}
.col-span-1 {
    grid-column: span 1 / span 1;
}
.col-span-10 {
    grid-column: span 10 / span 10;
}
.col-span-11 {
    grid-column: span 11 / span 11;
}
.col-span-12 {
    grid-column: span 12 / span 12;
}
.col-span-2 {
    grid-column: span 2 / span 2;
}
.col-span-3 {
    grid-column: span 3 / span 3;
}
.col-span-4 {
    grid-column: span 4 / span 4;
}
.col-span-5 {
    grid-column: span 5 / span 5;
}
.col-span-6 {
    grid-column: span 6 / span 6;
}
.col-span-7 {
    grid-column: span 7 / span 7;
}
.col-span-8 {
    grid-column: span 8 / span 8;
}
.col-span-9 {
    grid-column: span 9 / span 9;
}
.\!m-0 {
    margin: 0px !important;
}
.m-0 {
    margin: 0px;
}
.m-2 {
    margin: 0.5rem;
}
.m-6 {
    margin: 1.5rem;
}
.\!mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.\!my-0\.5 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
}
.\!my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.\!my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.\!my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.\!-mb-\[3px\] {
    margin-bottom: -3px !important;
}
.\!mb-0 {
    margin-bottom: 0px !important;
}
.\!mr-1 {
    margin-right: 0.25rem !important;
}
.-mb-10 {
    margin-bottom: -2.5rem;
}
.-mb-px {
    margin-bottom: -1px;
}
.-ml-0 {
    margin-left: -0px;
}
.-ml-0\.5 {
    margin-left: -0.125rem;
}
.-ml-1 {
    margin-left: -0.25rem;
}
.-ml-1\.5 {
    margin-left: -0.375rem;
}
.-ml-4 {
    margin-left: -1rem;
}
.-ml-px {
    margin-left: -1px;
}
.-mr-1 {
    margin-right: -0.25rem;
}
.-mr-1\.5 {
    margin-right: -0.375rem;
}
.-mr-2 {
    margin-right: -0.5rem;
}
.-mt-2 {
    margin-top: -0.5rem;
}
.-mt-3 {
    margin-top: -0.75rem;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-0\.5 {
    margin-bottom: 0.125rem;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-1\.5 {
    margin-bottom: 0.375rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-20 {
    margin-bottom: 5rem;
}
.mb-3 {
    margin-bottom: 0.75rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-\[13px\] {
    margin-bottom: 13px;
}
.mb-\[1rem\] {
    margin-bottom: 1rem;
}
.ml-1\.5 {
    margin-left: 0.375rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-4 {
    margin-left: 1rem;
}
.ml-6 {
    margin-left: 1.5rem;
}
.ml-px {
    margin-left: 1px;
}
.mr-0 {
    margin-right: 0px;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-1\.5 {
    margin-right: 0.375rem;
}
.mr-10 {
    margin-right: 2.5rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-2\.5 {
    margin-right: 0.625rem;
}
.mr-20 {
    margin-right: 5rem;
}
.mr-3 {
    margin-right: 0.75rem;
}
.mr-4 {
    margin-right: 1rem;
}
.mr-5 {
    margin-right: 1.25rem;
}
.mr-8 {
    margin-right: 2rem;
}
.mt-0 {
    margin-top: 0px;
}
.mt-0\.5 {
    margin-top: 0.125rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-12 {
    margin-top: 3rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.mt-\[0\.5rem\] {
    margin-top: 0.5rem;
}
.mt-\[1px\] {
    margin-top: 1px;
}
.mt-\[2px\] {
    margin-top: 2px;
}
.mt-\[2rem\] {
    margin-top: 2rem;
}
.mt-\[3px\] {
    margin-top: 3px;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.table {
    display: table;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.size-3\.5 {
    width: 0.875rem;
    height: 0.875rem;
}
.\!h-10 {
    height: 2.5rem !important;
}
.\!h-\[250px\] {
    height: 250px !important;
}
.\!h-\[340px\] {
    height: 340px !important;
}
.\!h-full {
    height: 100% !important;
}
.h-0 {
    height: 0px;
}
.h-1\.5 {
    height: 0.375rem;
}
.h-10 {
    height: 2.5rem;
}
.h-12 {
    height: 3rem;
}
.h-2 {
    height: 0.5rem;
}
.h-3 {
    height: 0.75rem;
}
.h-3\.5 {
    height: 0.875rem;
}
.h-4 {
    height: 1rem;
}
.h-40 {
    height: 10rem;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-7 {
    height: 1.75rem;
}
.h-8 {
    height: 2rem;
}
.h-80 {
    height: 20rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[168px\] {
    height: 168px;
}
.h-\[1px\] {
    height: 1px;
}
.h-\[24px\] {
    height: 24px;
}
.h-\[345px\] {
    height: 345px;
}
.h-\[392px\] {
    height: 392px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[44px\] {
    height: 44px;
}
.h-\[48px\] {
    height: 48px;
}
.h-\[51px\] {
    height: 51px;
}
.h-\[56px\] {
    height: 56px;
}
.h-\[680px\] {
    height: 680px;
}
.h-\[94\%\] {
    height: 94%;
}
.h-\[calc\(100dvh-70px\)\] {
    height: calc(100dvh - 70px);
}
.h-full {
    height: 100%;
}
.h-screen {
    height: 100vh;
}
.\!max-h-screen {
    max-height: 100vh !important;
}
.max-h-\[200px\] {
    max-height: 200px;
}
.max-h-\[228px\] {
    max-height: 228px;
}
.max-h-\[300px\] {
    max-height: 300px;
}
.max-h-\[380px\] {
    max-height: 380px;
}
.max-h-full {
    max-height: 100%;
}
.\!min-h-0\.5 {
    min-height: 0.125rem !important;
}
.min-h-16 {
    min-height: 4rem;
}
.min-h-4 {
    min-height: 1rem;
}
.min-h-\[inherit\] {
    min-height: inherit;
}
.min-h-full {
    min-height: 100%;
}
.\!w-\[200px\] {
    width: 200px !important;
}
.\!w-\[256px\] {
    width: 256px !important;
}
.\!w-\[399\.2px\] {
    width: 399.2px !important;
}
.\!w-\[400px\] {
    width: 400px !important;
}
.\!w-\[536px\] {
    width: 536px !important;
}
.\!w-auto {
    width: auto !important;
}
.\!w-full {
    width: 100% !important;
}
.w-0 {
    width: 0px;
}
.w-1 {
    width: 0.25rem;
}
.w-1\.5 {
    width: 0.375rem;
}
.w-1\/2 {
    width: 50%;
}
.w-10 {
    width: 2.5rem;
}
.w-16 {
    width: 4rem;
}
.w-2 {
    width: 0.5rem;
}
.w-28 {
    width: 7rem;
}
.w-3 {
    width: 0.75rem;
}
.w-3\.5 {
    width: 0.875rem;
}
.w-4 {
    width: 1rem;
}
.w-48 {
    width: 12rem;
}
.w-5 {
    width: 1.25rem;
}
.w-6 {
    width: 1.5rem;
}
.w-7 {
    width: 1.75rem;
}
.w-8 {
    width: 2rem;
}
.w-8\/12 {
    width: 66.666667%;
}
.w-9 {
    width: 2.25rem;
}
.w-\[100\%\] {
    width: 100%;
}
.w-\[100px\] {
    width: 100px;
}
.w-\[120px\] {
    width: 120px;
}
.w-\[180px\] {
    width: 180px;
}
.w-\[186px\] {
    width: 186px;
}
.w-\[200px\] {
    width: 200px;
}
.w-\[220px\] {
    width: 220px;
}
.w-\[235px\] {
    width: 235px;
}
.w-\[236px\] {
    width: 236px;
}
.w-\[240px\] {
    width: 240px;
}
.w-\[256px\] {
    width: 256px;
}
.w-\[300px\] {
    width: 300px;
}
.w-\[320px\] {
    width: 320px;
}
.w-\[400px\] {
    width: 400px;
}
.w-\[420px\] {
    width: 420px;
}
.w-\[430px\] {
    width: 430px;
}
.w-\[492px\] {
    width: 492px;
}
.w-\[49px\] {
    width: 49px;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[60px\] {
    width: 60px;
}
.w-\[70\%\] {
    width: 70%;
}
.w-\[80px\] {
    width: 80px;
}
.w-\[85\%\] {
    width: 85%;
}
.w-\[90\%\] {
    width: 90%;
}
.w-\[90px\] {
    width: 90px;
}
.w-\[var\(--button-width\)\] {
    width: var(--button-width);
}
.w-fit {
    width: -moz-fit-content;
    width: fit-content;
}
.w-full {
    width: 100%;
}
.w-max {
    width: -moz-max-content;
    width: max-content;
}
.w-px {
    width: 1px;
}
.w-screen {
    width: 100vw;
}
.\!min-w-\[400px\] {
    min-width: 400px !important;
}
.min-w-44 {
    min-width: 11rem;
}
.min-w-\[10rem\] {
    min-width: 10rem;
}
.min-w-\[120px\] {
    min-width: 120px;
}
.min-w-\[400px\] {
    min-width: 400px;
}
.min-w-fit {
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.min-w-min {
    min-width: -moz-min-content;
    min-width: min-content;
}
.max-w-\[100px\] {
    max-width: 100px;
}
.max-w-\[300px\] {
    max-width: 300px;
}
.max-w-\[95px\] {
    max-width: 95px;
}
.max-w-full {
    max-width: 100%;
}
.max-w-lg {
    max-width: 32rem;
}
.max-w-sm {
    max-width: 24rem;
}
.max-w-xs {
    max-width: 20rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-\[0\.25\] {
    flex: 0.25;
}
.flex-\[0\.3\] {
    flex: 0.3;
}
.flex-\[0\.45\] {
    flex: 0.45;
}
.flex-\[0\.7\] {
    flex: 0.7;
}
.flex-none {
    flex: none;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.border-collapse {
    border-collapse: collapse;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-5 {
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-x-0 {
    --tw-skew-x: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-y-0 {
    --tw-skew-y: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.animate-spin {
    animation: spin 1s linear infinite;
}
.\!cursor-default {
    cursor: default !important;
}
.cursor-default {
    cursor: default;
}
.cursor-move {
    cursor: move;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.resize {
    resize: both;
}
.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
}
.list-none {
    list-style-type: none;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-none {
    grid-template-columns: none;
}
.\!flex-row {
    flex-direction: row !important;
}
.flex-row {
    flex-direction: row;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col {
    flex-direction: column;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.place-items-center {
    place-items: center;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.justify-evenly {
    justify-content: space-evenly;
}
.\!gap-0 {
    gap: 0px !important;
}
.gap-0\.5 {
    gap: 0.125rem;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-tremor-border > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}
.self-start {
    align-self: flex-start;
}
.self-stretch {
    align-self: stretch;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-x-auto {
    overflow-x: auto;
}
.\!overflow-y-auto {
    overflow-y: auto !important;
}
.overflow-y-auto {
    overflow-y: auto;
}
.\!overflow-x-hidden {
    overflow-x: hidden !important;
}
.\!overflow-y-hidden {
    overflow-y: hidden !important;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.overflow-x-clip {
    overflow-x: clip;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-break-spaces {
    white-space: break-spaces;
}
.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.break-words {
    overflow-wrap: break-word;
}
.\!rounded {
    border-radius: 0.25rem !important;
}
.\!rounded-none {
    border-radius: 0px !important;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-\[1px\] {
    border-radius: 1px;
}
.rounded-\[theme\(spacing\.2\)\] {
    border-radius: 0.5rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-none {
    border-radius: 0px;
}
.rounded-tremor-default {
    border-radius: 0.5rem;
}
.rounded-tremor-full {
    border-radius: 9999px;
}
.rounded-tremor-small {
    border-radius: 0.375rem;
}
.rounded-b-tremor-default {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.rounded-l-tremor-default {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.rounded-l-tremor-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}
.rounded-l-tremor-small {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
.rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.rounded-r-tremor-default {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.rounded-r-tremor-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.rounded-r-tremor-small {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}
.rounded-t-tremor-default {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.rounded-br-\[1\.5rem\] {
    border-bottom-right-radius: 1.5rem;
}
.rounded-tr-\[1\.5rem\] {
    border-top-right-radius: 1.5rem;
}
.\!border {
    border-width: 1px !important;
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-2 {
    border-width: 2px;
}
.\!border-t-0 {
    border-top-width: 0px !important;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-4 {
    border-bottom-width: 4px;
}
.border-l {
    border-left-width: 1px;
}
.border-l-4 {
    border-left-width: 4px;
}
.border-r {
    border-right-width: 1px;
}
.border-r-4 {
    border-right-width: 4px;
}
.border-t {
    border-top-width: 1px;
}
.border-t-4 {
    border-top-width: 4px;
}
.border-t-\[1px\] {
    border-top-width: 1px;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.\!border-none {
    border-style: none !important;
}
.border-none {
    border-style: none;
}
.\!border-\[\#BDC7D1\] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(189 199 209 / var(--tw-border-opacity, 1)) !important;
}
.\!border-blue-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1)) !important;
}
.border-\[\#758391\] {
    --tw-border-opacity: 1;
    border-color: rgb(117 131 145 / var(--tw-border-opacity, 1));
}
.border-\[\#BDC7D1\] {
    --tw-border-opacity: 1;
    border-color: rgb(189 199 209 / var(--tw-border-opacity, 1));
}
.border-\[\#f0f0f0\] {
    --tw-border-opacity: 1;
    border-color: rgb(240 240 240 / var(--tw-border-opacity, 1));
}
.border-\[rgba\(5\2c 23\2c 41\2c 0\.06\)\] {
    border-color: rgba(5,23,41,0.06);
}
.border-\[transparent\] {
    border-color: transparent;
}
.border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity, 1));
}
.border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity, 1));
}
.border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity, 1));
}
.border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}
.border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity, 1));
}
.border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}
.border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity, 1));
}
.border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity, 1));
}
.border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity, 1));
}
.border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity, 1));
}
.border-amber-950 {
    --tw-border-opacity: 1;
    border-color: rgb(69 26 3 / var(--tw-border-opacity, 1));
}
.border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity, 1));
}
.border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}
.border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}
.border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}
.border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity, 1));
}
.border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
.border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}
.border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}
.border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}
.border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}
.border-blue-950 {
    --tw-border-opacity: 1;
    border-color: rgb(23 37 84 / var(--tw-border-opacity, 1));
}
.border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity, 1));
}
.border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity, 1));
}
.border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}
.border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}
.border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity, 1));
}
.border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}
.border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
}
.border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity, 1));
}
.border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity, 1));
}
.border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity, 1));
}
.border-cyan-950 {
    --tw-border-opacity: 1;
    border-color: rgb(8 51 68 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-background {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-border {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-brand {
    --tw-border-opacity: 1;
    border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-brand-emphasis {
    --tw-border-opacity: 1;
    border-color: rgb(9 88 217 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-brand-inverted {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-dark-tremor-brand-subtle {
    --tw-border-opacity: 1;
    border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
}
.border-emerald-100 {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity, 1));
}
.border-emerald-200 {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity, 1));
}
.border-emerald-300 {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity, 1));
}
.border-emerald-400 {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity, 1));
}
.border-emerald-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity, 1));
}
.border-emerald-500 {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}
.border-emerald-600 {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity, 1));
}
.border-emerald-700 {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity, 1));
}
.border-emerald-800 {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity, 1));
}
.border-emerald-900 {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity, 1));
}
.border-emerald-950 {
    --tw-border-opacity: 1;
    border-color: rgb(2 44 34 / var(--tw-border-opacity, 1));
}
.border-fuchsia-100 {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity, 1));
}
.border-fuchsia-200 {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity, 1));
}
.border-fuchsia-300 {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity, 1));
}
.border-fuchsia-400 {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity, 1));
}
.border-fuchsia-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity, 1));
}
.border-fuchsia-500 {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}
.border-fuchsia-600 {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity, 1));
}
.border-fuchsia-700 {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity, 1));
}
.border-fuchsia-800 {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity, 1));
}
.border-fuchsia-900 {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity, 1));
}
.border-fuchsia-950 {
    --tw-border-opacity: 1;
    border-color: rgb(74 4 78 / var(--tw-border-opacity, 1));
}
.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}
.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}
.border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}
.border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}
.border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}
.border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
}
.border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}
.border-gray-950 {
    --tw-border-opacity: 1;
    border-color: rgb(3 7 18 / var(--tw-border-opacity, 1));
}
.border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity, 1));
}
.border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}
.border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}
.border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}
.border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity, 1));
}
.border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}
.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}
.border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}
.border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}
.border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}
.border-green-950 {
    --tw-border-opacity: 1;
    border-color: rgb(5 46 22 / var(--tw-border-opacity, 1));
}
.border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}
.border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}
.border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}
.border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity, 1));
}
.border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}
.border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}
.border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}
.border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}
.border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}
.border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity, 1));
}
.border-indigo-950 {
    --tw-border-opacity: 1;
    border-color: rgb(30 27 75 / var(--tw-border-opacity, 1));
}
.border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity, 1));
}
.border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity, 1));
}
.border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
}
.border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity, 1));
}
.border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity, 1));
}
.border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}
.border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}
.border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}
.border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}
.border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity, 1));
}
.border-lime-950 {
    --tw-border-opacity: 1;
    border-color: rgb(26 46 5 / var(--tw-border-opacity, 1));
}
.border-neutral-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity, 1));
}
.border-neutral-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}
.border-neutral-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}
.border-neutral-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity, 1));
}
.border-neutral-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.border-neutral-500 {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity, 1));
}
.border-neutral-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity, 1));
}
.border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity, 1));
}
.border-neutral-800 {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity, 1));
}
.border-neutral-900 {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity, 1));
}
.border-neutral-950 {
    --tw-border-opacity: 1;
    border-color: rgb(10 10 10 / var(--tw-border-opacity, 1));
}
.border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity, 1));
}
.border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}
.border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity, 1));
}
.border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
}
.border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity, 1));
}
.border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}
.border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity, 1));
}
.border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity, 1));
}
.border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity, 1));
}
.border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity, 1));
}
.border-orange-950 {
    --tw-border-opacity: 1;
    border-color: rgb(67 20 7 / var(--tw-border-opacity, 1));
}
.border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity, 1));
}
.border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity, 1));
}
.border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity, 1));
}
.border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}
.border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}
.border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}
.border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity, 1));
}
.border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity, 1));
}
.border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgb(157 23 77 / var(--tw-border-opacity, 1));
}
.border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity, 1));
}
.border-pink-950 {
    --tw-border-opacity: 1;
    border-color: rgb(80 7 36 / var(--tw-border-opacity, 1));
}
.border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity, 1));
}
.border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity, 1));
}
.border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity, 1));
}
.border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
}
.border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity, 1));
}
.border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}
.border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
.border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity, 1));
}
.border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity, 1));
}
.border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity, 1));
}
.border-purple-950 {
    --tw-border-opacity: 1;
    border-color: rgb(59 7 100 / var(--tw-border-opacity, 1));
}
.border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity, 1));
}
.border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}
.border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}
.border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}
.border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity, 1));
}
.border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
.border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}
.border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}
.border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
}
.border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity, 1));
}
.border-red-950 {
    --tw-border-opacity: 1;
    border-color: rgb(69 10 10 / var(--tw-border-opacity, 1));
}
.border-rose-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity, 1));
}
.border-rose-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity, 1));
}
.border-rose-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity, 1));
}
.border-rose-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity, 1));
}
.border-rose-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity, 1));
}
.border-rose-500 {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}
.border-rose-600 {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity, 1));
}
.border-rose-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity, 1));
}
.border-rose-800 {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity, 1));
}
.border-rose-900 {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity, 1));
}
.border-rose-950 {
    --tw-border-opacity: 1;
    border-color: rgb(76 5 25 / var(--tw-border-opacity, 1));
}
.border-sky-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}
.border-sky-200 {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}
.border-sky-300 {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity, 1));
}
.border-sky-400 {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}
.border-sky-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}
.border-sky-500 {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}
.border-sky-600 {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}
.border-sky-700 {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}
.border-sky-800 {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}
.border-sky-900 {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}
.border-sky-950 {
    --tw-border-opacity: 1;
    border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}
.border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}
.border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}
.border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}
.border-slate-400 {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}
.border-slate-50 {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity, 1));
}
.border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
.border-slate-600 {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}
.border-slate-700 {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}
.border-slate-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}
.border-slate-900 {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity, 1));
}
.border-slate-950 {
    --tw-border-opacity: 1;
    border-color: rgb(2 6 23 / var(--tw-border-opacity, 1));
}
.border-stone-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity, 1));
}
.border-stone-200 {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity, 1));
}
.border-stone-300 {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity, 1));
}
.border-stone-400 {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity, 1));
}
.border-stone-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity, 1));
}
.border-stone-500 {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity, 1));
}
.border-stone-600 {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity, 1));
}
.border-stone-700 {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity, 1));
}
.border-stone-800 {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity, 1));
}
.border-stone-900 {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity, 1));
}
.border-stone-950 {
    --tw-border-opacity: 1;
    border-color: rgb(12 10 9 / var(--tw-border-opacity, 1));
}
.border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity, 1));
}
.border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}
.border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity, 1));
}
.border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity, 1));
}
.border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity, 1));
}
.border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}
.border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}
.border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity, 1));
}
.border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity, 1));
}
.border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity, 1));
}
.border-teal-950 {
    --tw-border-opacity: 1;
    border-color: rgb(4 47 46 / var(--tw-border-opacity, 1));
}
.border-transparent {
    border-color: transparent;
}
.border-tremor-background {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-tremor-border {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-tremor-brand {
    --tw-border-opacity: 1;
    border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
}
.border-tremor-brand-emphasis {
    --tw-border-opacity: 1;
    border-color: rgb(9 88 217 / var(--tw-border-opacity, 1));
}
.border-tremor-brand-inverted {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-tremor-brand-subtle {
    --tw-border-opacity: 1;
    border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
}
.border-violet-100 {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity, 1));
}
.border-violet-200 {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity, 1));
}
.border-violet-300 {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity, 1));
}
.border-violet-400 {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity, 1));
}
.border-violet-50 {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity, 1));
}
.border-violet-500 {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}
.border-violet-600 {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity, 1));
}
.border-violet-700 {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity, 1));
}
.border-violet-800 {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity, 1));
}
.border-violet-900 {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity, 1));
}
.border-violet-950 {
    --tw-border-opacity: 1;
    border-color: rgb(46 16 101 / var(--tw-border-opacity, 1));
}
.border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity, 1));
}
.border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity, 1));
}
.border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity, 1));
}
.border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity, 1));
}
.border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity, 1));
}
.border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}
.border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity, 1));
}
.border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity, 1));
}
.border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity, 1));
}
.border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity, 1));
}
.border-yellow-950 {
    --tw-border-opacity: 1;
    border-color: rgb(66 32 6 / var(--tw-border-opacity, 1));
}
.border-zinc-100 {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity, 1));
}
.border-zinc-200 {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity, 1));
}
.border-zinc-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity, 1));
}
.border-zinc-400 {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity, 1));
}
.border-zinc-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.border-zinc-500 {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity, 1));
}
.border-zinc-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity, 1));
}
.border-zinc-700 {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity, 1));
}
.border-zinc-800 {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity, 1));
}
.border-zinc-900 {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity, 1));
}
.border-zinc-950 {
    --tw-border-opacity: 1;
    border-color: rgb(9 9 11 / var(--tw-border-opacity, 1));
}
.border-t-\[rgba\(5\2c 23\2c 41\2c 0\.06\)\] {
    border-top-color: rgba(5,23,41,0.06);
}
.\!bg-\[\#1C2C3D\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(28 44 61 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-\[\#fafafa\] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-transparent {
    background-color: transparent !important;
}
.bg-\[\#0517290A\] {
    background-color: #0517290A;
}
.bg-\[\#0517290F\] {
    background-color: #0517290F;
}
.bg-\[\#070f03\] {
    --tw-bg-opacity: 1;
    background-color: rgb(7 15 3 / var(--tw-bg-opacity, 1));
}
.bg-\[\#D9F7BE\] {
    --tw-bg-opacity: 1;
    background-color: rgb(217 247 190 / var(--tw-bg-opacity, 1));
}
.bg-\[\#E6F4FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(230 244 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#F9F0FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 240 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFF2E8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 242 232 / var(--tw-bg-opacity, 1));
}
.bg-\[\#f5f7fa\] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 247 250 / var(--tw-bg-opacity, 1));
}
.bg-\[rgba\(5\2c 23\2c 41\2c 0\.04\)\] {
    background-color: rgba(5,23,41,0.04);
}
.bg-\[rgba\(5\2c 23\2c 41\2c 0\.06\)\] {
    background-color: rgba(5,23,41,0.06);
}
.bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}
.bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity, 1));
}
.bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity, 1));
}
.bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}
.bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}
.bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}
.bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}
.bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity, 1));
}
.bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
}
.bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity, 1));
}
.bg-amber-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(69 26 3 / var(--tw-bg-opacity, 1));
}
.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}
.bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}
.bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}
.bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}
.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}
.bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}
.bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}
.bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}
.bg-blue-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 37 84 / var(--tw-bg-opacity, 1));
}
.bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}
.bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity, 1));
}
.bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}
.bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}
.bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity, 1));
}
.bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}
.bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}
.bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}
.bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity, 1));
}
.bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity, 1));
}
.bg-cyan-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 51 68 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-background {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-background-subtle {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-brand {
    --tw-bg-opacity: 1;
    background-color: rgb(22 119 255 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-brand-emphasis {
    --tw-bg-opacity: 1;
    background-color: rgb(9 88 217 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-brand-faint {
    --tw-bg-opacity: 1;
    background-color: rgb(186 224 255 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-brand-muted {
    --tw-bg-opacity: 1;
    background-color: rgb(64 150 255 / var(--tw-bg-opacity, 1));
}
.bg-dark-tremor-content-subtle {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}
.bg-emerald-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}
.bg-emerald-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity, 1));
}
.bg-emerald-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity, 1));
}
.bg-emerald-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}
.bg-emerald-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity, 1));
}
.bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}
.bg-emerald-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}
.bg-emerald-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity, 1));
}
.bg-emerald-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}
.bg-emerald-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity, 1));
}
.bg-emerald-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 44 34 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity, 1));
}
.bg-fuchsia-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 4 78 / var(--tw-bg-opacity, 1));
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}
.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}
.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}
.bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity, 1));
}
.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}
.bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}
.bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}
.bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}
.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}
.bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}
.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}
.bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}
.bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}
.bg-green-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 46 22 / var(--tw-bg-opacity, 1));
}
.bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}
.bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}
.bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}
.bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity, 1));
}
.bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}
.bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}
.bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}
.bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}
.bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}
.bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity, 1));
}
.bg-indigo-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 27 75 / var(--tw-bg-opacity, 1));
}
.bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}
.bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}
.bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity, 1));
}
.bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity, 1));
}
.bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}
.bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}
.bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}
.bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity, 1));
}
.bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}
.bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity, 1));
}
.bg-lime-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 46 5 / var(--tw-bg-opacity, 1));
}
.bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}
.bg-neutral-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}
.bg-neutral-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}
.bg-neutral-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1));
}
.bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.bg-neutral-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity, 1));
}
.bg-neutral-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity, 1));
}
.bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity, 1));
}
.bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}
.bg-neutral-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
}
.bg-neutral-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(10 10 10 / var(--tw-bg-opacity, 1));
}
.bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}
.bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}
.bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity, 1));
}
.bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity, 1));
}
.bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}
.bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}
.bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity, 1));
}
.bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity, 1));
}
.bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity, 1));
}
.bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity, 1));
}
.bg-orange-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(67 20 7 / var(--tw-bg-opacity, 1));
}
.bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}
.bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity, 1));
}
.bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity, 1));
}
.bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}
.bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}
.bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}
.bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
}
.bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity, 1));
}
.bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(157 23 77 / var(--tw-bg-opacity, 1));
}
.bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity, 1));
}
.bg-pink-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(80 7 36 / var(--tw-bg-opacity, 1));
}
.bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
.bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}
.bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity, 1));
}
.bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}
.bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity, 1));
}
.bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}
.bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}
.bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}
.bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity, 1));
}
.bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}
.bg-purple-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 7 100 / var(--tw-bg-opacity, 1));
}
.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}
.bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
}
.bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}
.bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}
.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}
.bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}
.bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}
.bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}
.bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
}
.bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity, 1));
}
.bg-red-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(69 10 10 / var(--tw-bg-opacity, 1));
}
.bg-rose-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}
.bg-rose-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity, 1));
}
.bg-rose-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity, 1));
}
.bg-rose-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity, 1));
}
.bg-rose-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity, 1));
}
.bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}
.bg-rose-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1));
}
.bg-rose-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity, 1));
}
.bg-rose-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity, 1));
}
.bg-rose-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity, 1));
}
.bg-rose-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(76 5 25 / var(--tw-bg-opacity, 1));
}
.bg-sky-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}
.bg-sky-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}
.bg-sky-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity, 1));
}
.bg-sky-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}
.bg-sky-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}
.bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}
.bg-sky-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}
.bg-sky-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}
.bg-sky-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}
.bg-sky-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}
.bg-sky-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}
.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}
.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}
.bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}
.bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}
.bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}
.bg-slate-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}
.bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}
.bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}
.bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}
.bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity, 1));
}
.bg-slate-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity, 1));
}
.bg-slate-950\/30 {
    background-color: rgb(2 6 23 / 0.3);
}
.bg-stone-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}
.bg-stone-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}
.bg-stone-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity, 1));
}
.bg-stone-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity, 1));
}
.bg-stone-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity, 1));
}
.bg-stone-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}
.bg-stone-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity, 1));
}
.bg-stone-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity, 1));
}
.bg-stone-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}
.bg-stone-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}
.bg-stone-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 10 9 / var(--tw-bg-opacity, 1));
}
.bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}
.bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}
.bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity, 1));
}
.bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
}
.bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity, 1));
}
.bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}
.bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}
.bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity, 1));
}
.bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity, 1));
}
.bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity, 1));
}
.bg-teal-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 47 46 / var(--tw-bg-opacity, 1));
}
.bg-transparent {
    background-color: transparent;
}
.bg-tremor-background {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-tremor-background-emphasis {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.bg-tremor-background-muted {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.bg-tremor-background-subtle {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-tremor-border {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.bg-tremor-brand {
    --tw-bg-opacity: 1;
    background-color: rgb(22 119 255 / var(--tw-bg-opacity, 1));
}
.bg-tremor-brand-faint {
    --tw-bg-opacity: 1;
    background-color: rgb(186 224 255 / var(--tw-bg-opacity, 1));
}
.bg-tremor-brand-muted {
    --tw-bg-opacity: 1;
    background-color: rgb(105 177 255 / var(--tw-bg-opacity, 1));
}
.bg-tremor-brand-muted\/50 {
    background-color: rgb(105 177 255 / 0.5);
}
.bg-tremor-brand-subtle {
    --tw-bg-opacity: 1;
    background-color: rgb(64 150 255 / var(--tw-bg-opacity, 1));
}
.bg-tremor-content-subtle {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
.bg-violet-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}
.bg-violet-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity, 1));
}
.bg-violet-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity, 1));
}
.bg-violet-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity, 1));
}
.bg-violet-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity, 1));
}
.bg-violet-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}
.bg-violet-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity, 1));
}
.bg-violet-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity, 1));
}
.bg-violet-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity, 1));
}
.bg-violet-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity, 1));
}
.bg-violet-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(46 16 101 / var(--tw-bg-opacity, 1));
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
.bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}
.bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity, 1));
}
.bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity, 1));
}
.bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity, 1));
}
.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}
.bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
}
.bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity, 1));
}
.bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity, 1));
}
.bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity, 1));
}
.bg-yellow-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(66 32 6 / var(--tw-bg-opacity, 1));
}
.bg-zinc-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity, 1));
}
.bg-zinc-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));
}
.bg-zinc-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity, 1));
}
.bg-zinc-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity, 1));
}
.bg-zinc-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.bg-zinc-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity, 1));
}
.bg-zinc-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity, 1));
}
.bg-zinc-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity, 1));
}
.bg-zinc-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1));
}
.bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity, 1));
}
.bg-zinc-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity, 1));
}
.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}
.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}
.bg-opacity-40 {
    --tw-bg-opacity: 0.4;
}
.bg-repeat-y {
    background-repeat: repeat-y;
}
.fill-amber-100 {
    fill: #fef3c7;
}
.fill-amber-200 {
    fill: #fde68a;
}
.fill-amber-300 {
    fill: #fcd34d;
}
.fill-amber-400 {
    fill: #fbbf24;
}
.fill-amber-50 {
    fill: #fffbeb;
}
.fill-amber-500 {
    fill: #f59e0b;
}
.fill-amber-600 {
    fill: #d97706;
}
.fill-amber-700 {
    fill: #b45309;
}
.fill-amber-800 {
    fill: #92400e;
}
.fill-amber-900 {
    fill: #78350f;
}
.fill-amber-950 {
    fill: #451a03;
}
.fill-blue-100 {
    fill: #dbeafe;
}
.fill-blue-200 {
    fill: #bfdbfe;
}
.fill-blue-300 {
    fill: #93c5fd;
}
.fill-blue-400 {
    fill: #60a5fa;
}
.fill-blue-50 {
    fill: #eff6ff;
}
.fill-blue-500 {
    fill: #3b82f6;
}
.fill-blue-600 {
    fill: #2563eb;
}
.fill-blue-700 {
    fill: #1d4ed8;
}
.fill-blue-800 {
    fill: #1e40af;
}
.fill-blue-900 {
    fill: #1e3a8a;
}
.fill-blue-950 {
    fill: #172554;
}
.fill-cyan-100 {
    fill: #cffafe;
}
.fill-cyan-200 {
    fill: #a5f3fc;
}
.fill-cyan-300 {
    fill: #67e8f9;
}
.fill-cyan-400 {
    fill: #22d3ee;
}
.fill-cyan-50 {
    fill: #ecfeff;
}
.fill-cyan-500 {
    fill: #06b6d4;
}
.fill-cyan-600 {
    fill: #0891b2;
}
.fill-cyan-700 {
    fill: #0e7490;
}
.fill-cyan-800 {
    fill: #155e75;
}
.fill-cyan-900 {
    fill: #164e63;
}
.fill-cyan-950 {
    fill: #083344;
}
.fill-dark-tremor-content {
    fill: #6b7280;
}
.fill-dark-tremor-content-emphasis {
    fill: #e5e7eb;
}
.fill-emerald-100 {
    fill: #d1fae5;
}
.fill-emerald-200 {
    fill: #a7f3d0;
}
.fill-emerald-300 {
    fill: #6ee7b7;
}
.fill-emerald-400 {
    fill: #34d399;
}
.fill-emerald-50 {
    fill: #ecfdf5;
}
.fill-emerald-500 {
    fill: #10b981;
}
.fill-emerald-600 {
    fill: #059669;
}
.fill-emerald-700 {
    fill: #047857;
}
.fill-emerald-800 {
    fill: #065f46;
}
.fill-emerald-900 {
    fill: #064e3b;
}
.fill-emerald-950 {
    fill: #022c22;
}
.fill-fuchsia-100 {
    fill: #fae8ff;
}
.fill-fuchsia-200 {
    fill: #f5d0fe;
}
.fill-fuchsia-300 {
    fill: #f0abfc;
}
.fill-fuchsia-400 {
    fill: #e879f9;
}
.fill-fuchsia-50 {
    fill: #fdf4ff;
}
.fill-fuchsia-500 {
    fill: #d946ef;
}
.fill-fuchsia-600 {
    fill: #c026d3;
}
.fill-fuchsia-700 {
    fill: #a21caf;
}
.fill-fuchsia-800 {
    fill: #86198f;
}
.fill-fuchsia-900 {
    fill: #701a75;
}
.fill-fuchsia-950 {
    fill: #4a044e;
}
.fill-gray-100 {
    fill: #f3f4f6;
}
.fill-gray-200 {
    fill: #e5e7eb;
}
.fill-gray-300 {
    fill: #d1d5db;
}
.fill-gray-400 {
    fill: #9ca3af;
}
.fill-gray-50 {
    fill: #f9fafb;
}
.fill-gray-500 {
    fill: #6b7280;
}
.fill-gray-600 {
    fill: #4b5563;
}
.fill-gray-700 {
    fill: #374151;
}
.fill-gray-800 {
    fill: #1f2937;
}
.fill-gray-900 {
    fill: #111827;
}
.fill-gray-950 {
    fill: #030712;
}
.fill-green-100 {
    fill: #dcfce7;
}
.fill-green-200 {
    fill: #bbf7d0;
}
.fill-green-300 {
    fill: #86efac;
}
.fill-green-400 {
    fill: #4ade80;
}
.fill-green-50 {
    fill: #f0fdf4;
}
.fill-green-500 {
    fill: #22c55e;
}
.fill-green-600 {
    fill: #16a34a;
}
.fill-green-700 {
    fill: #15803d;
}
.fill-green-800 {
    fill: #166534;
}
.fill-green-900 {
    fill: #14532d;
}
.fill-green-950 {
    fill: #052e16;
}
.fill-indigo-100 {
    fill: #e0e7ff;
}
.fill-indigo-200 {
    fill: #c7d2fe;
}
.fill-indigo-300 {
    fill: #a5b4fc;
}
.fill-indigo-400 {
    fill: #818cf8;
}
.fill-indigo-50 {
    fill: #eef2ff;
}
.fill-indigo-500 {
    fill: #6366f1;
}
.fill-indigo-600 {
    fill: #4f46e5;
}
.fill-indigo-700 {
    fill: #4338ca;
}
.fill-indigo-800 {
    fill: #3730a3;
}
.fill-indigo-900 {
    fill: #312e81;
}
.fill-indigo-950 {
    fill: #1e1b4b;
}
.fill-lime-100 {
    fill: #ecfccb;
}
.fill-lime-200 {
    fill: #d9f99d;
}
.fill-lime-300 {
    fill: #bef264;
}
.fill-lime-400 {
    fill: #a3e635;
}
.fill-lime-50 {
    fill: #f7fee7;
}
.fill-lime-500 {
    fill: #84cc16;
}
.fill-lime-600 {
    fill: #65a30d;
}
.fill-lime-700 {
    fill: #4d7c0f;
}
.fill-lime-800 {
    fill: #3f6212;
}
.fill-lime-900 {
    fill: #365314;
}
.fill-lime-950 {
    fill: #1a2e05;
}
.fill-neutral-100 {
    fill: #f5f5f5;
}
.fill-neutral-200 {
    fill: #e5e5e5;
}
.fill-neutral-300 {
    fill: #d4d4d4;
}
.fill-neutral-400 {
    fill: #a3a3a3;
}
.fill-neutral-50 {
    fill: #fafafa;
}
.fill-neutral-500 {
    fill: #737373;
}
.fill-neutral-600 {
    fill: #525252;
}
.fill-neutral-700 {
    fill: #404040;
}
.fill-neutral-800 {
    fill: #262626;
}
.fill-neutral-900 {
    fill: #171717;
}
.fill-neutral-950 {
    fill: #0a0a0a;
}
.fill-orange-100 {
    fill: #ffedd5;
}
.fill-orange-200 {
    fill: #fed7aa;
}
.fill-orange-300 {
    fill: #fdba74;
}
.fill-orange-400 {
    fill: #fb923c;
}
.fill-orange-50 {
    fill: #fff7ed;
}
.fill-orange-500 {
    fill: #f97316;
}
.fill-orange-600 {
    fill: #ea580c;
}
.fill-orange-700 {
    fill: #c2410c;
}
.fill-orange-800 {
    fill: #9a3412;
}
.fill-orange-900 {
    fill: #7c2d12;
}
.fill-orange-950 {
    fill: #431407;
}
.fill-pink-100 {
    fill: #fce7f3;
}
.fill-pink-200 {
    fill: #fbcfe8;
}
.fill-pink-300 {
    fill: #f9a8d4;
}
.fill-pink-400 {
    fill: #f472b6;
}
.fill-pink-50 {
    fill: #fdf2f8;
}
.fill-pink-500 {
    fill: #ec4899;
}
.fill-pink-600 {
    fill: #db2777;
}
.fill-pink-700 {
    fill: #be185d;
}
.fill-pink-800 {
    fill: #9d174d;
}
.fill-pink-900 {
    fill: #831843;
}
.fill-pink-950 {
    fill: #500724;
}
.fill-purple-100 {
    fill: #f3e8ff;
}
.fill-purple-200 {
    fill: #e9d5ff;
}
.fill-purple-300 {
    fill: #d8b4fe;
}
.fill-purple-400 {
    fill: #c084fc;
}
.fill-purple-50 {
    fill: #faf5ff;
}
.fill-purple-500 {
    fill: #a855f7;
}
.fill-purple-600 {
    fill: #9333ea;
}
.fill-purple-700 {
    fill: #7e22ce;
}
.fill-purple-800 {
    fill: #6b21a8;
}
.fill-purple-900 {
    fill: #581c87;
}
.fill-purple-950 {
    fill: #3b0764;
}
.fill-red-100 {
    fill: #fee2e2;
}
.fill-red-200 {
    fill: #fecaca;
}
.fill-red-300 {
    fill: #fca5a5;
}
.fill-red-400 {
    fill: #f87171;
}
.fill-red-50 {
    fill: #fef2f2;
}
.fill-red-500 {
    fill: #ef4444;
}
.fill-red-600 {
    fill: #dc2626;
}
.fill-red-700 {
    fill: #b91c1c;
}
.fill-red-800 {
    fill: #991b1b;
}
.fill-red-900 {
    fill: #7f1d1d;
}
.fill-red-950 {
    fill: #450a0a;
}
.fill-rose-100 {
    fill: #ffe4e6;
}
.fill-rose-200 {
    fill: #fecdd3;
}
.fill-rose-300 {
    fill: #fda4af;
}
.fill-rose-400 {
    fill: #fb7185;
}
.fill-rose-50 {
    fill: #fff1f2;
}
.fill-rose-500 {
    fill: #f43f5e;
}
.fill-rose-600 {
    fill: #e11d48;
}
.fill-rose-700 {
    fill: #be123c;
}
.fill-rose-800 {
    fill: #9f1239;
}
.fill-rose-900 {
    fill: #881337;
}
.fill-rose-950 {
    fill: #4c0519;
}
.fill-sky-100 {
    fill: #e0f2fe;
}
.fill-sky-200 {
    fill: #bae6fd;
}
.fill-sky-300 {
    fill: #7dd3fc;
}
.fill-sky-400 {
    fill: #38bdf8;
}
.fill-sky-50 {
    fill: #f0f9ff;
}
.fill-sky-500 {
    fill: #0ea5e9;
}
.fill-sky-600 {
    fill: #0284c7;
}
.fill-sky-700 {
    fill: #0369a1;
}
.fill-sky-800 {
    fill: #075985;
}
.fill-sky-900 {
    fill: #0c4a6e;
}
.fill-sky-950 {
    fill: #082f49;
}
.fill-slate-100 {
    fill: #f1f5f9;
}
.fill-slate-200 {
    fill: #e2e8f0;
}
.fill-slate-300 {
    fill: #cbd5e1;
}
.fill-slate-400 {
    fill: #94a3b8;
}
.fill-slate-50 {
    fill: #f8fafc;
}
.fill-slate-500 {
    fill: #64748b;
}
.fill-slate-600 {
    fill: #475569;
}
.fill-slate-700 {
    fill: #334155;
}
.fill-slate-800 {
    fill: #1e293b;
}
.fill-slate-900 {
    fill: #0f172a;
}
.fill-slate-950 {
    fill: #020617;
}
.fill-stone-100 {
    fill: #f5f5f4;
}
.fill-stone-200 {
    fill: #e7e5e4;
}
.fill-stone-300 {
    fill: #d6d3d1;
}
.fill-stone-400 {
    fill: #a8a29e;
}
.fill-stone-50 {
    fill: #fafaf9;
}
.fill-stone-500 {
    fill: #78716c;
}
.fill-stone-600 {
    fill: #57534e;
}
.fill-stone-700 {
    fill: #44403c;
}
.fill-stone-800 {
    fill: #292524;
}
.fill-stone-900 {
    fill: #1c1917;
}
.fill-stone-950 {
    fill: #0c0a09;
}
.fill-teal-100 {
    fill: #ccfbf1;
}
.fill-teal-200 {
    fill: #99f6e4;
}
.fill-teal-300 {
    fill: #5eead4;
}
.fill-teal-400 {
    fill: #2dd4bf;
}
.fill-teal-50 {
    fill: #f0fdfa;
}
.fill-teal-500 {
    fill: #14b8a6;
}
.fill-teal-600 {
    fill: #0d9488;
}
.fill-teal-700 {
    fill: #0f766e;
}
.fill-teal-800 {
    fill: #115e59;
}
.fill-teal-900 {
    fill: #134e4a;
}
.fill-teal-950 {
    fill: #042f2e;
}
.fill-tremor-content {
    fill: #6b7280;
}
.fill-tremor-content-emphasis {
    fill: #374151;
}
.fill-violet-100 {
    fill: #ede9fe;
}
.fill-violet-200 {
    fill: #ddd6fe;
}
.fill-violet-300 {
    fill: #c4b5fd;
}
.fill-violet-400 {
    fill: #a78bfa;
}
.fill-violet-50 {
    fill: #f5f3ff;
}
.fill-violet-500 {
    fill: #8b5cf6;
}
.fill-violet-600 {
    fill: #7c3aed;
}
.fill-violet-700 {
    fill: #6d28d9;
}
.fill-violet-800 {
    fill: #5b21b6;
}
.fill-violet-900 {
    fill: #4c1d95;
}
.fill-violet-950 {
    fill: #2e1065;
}
.fill-yellow-100 {
    fill: #fef9c3;
}
.fill-yellow-200 {
    fill: #fef08a;
}
.fill-yellow-300 {
    fill: #fde047;
}
.fill-yellow-400 {
    fill: #facc15;
}
.fill-yellow-50 {
    fill: #fefce8;
}
.fill-yellow-500 {
    fill: #eab308;
}
.fill-yellow-600 {
    fill: #ca8a04;
}
.fill-yellow-700 {
    fill: #a16207;
}
.fill-yellow-800 {
    fill: #854d0e;
}
.fill-yellow-900 {
    fill: #713f12;
}
.fill-yellow-950 {
    fill: #422006;
}
.fill-zinc-100 {
    fill: #f4f4f5;
}
.fill-zinc-200 {
    fill: #e4e4e7;
}
.fill-zinc-300 {
    fill: #d4d4d8;
}
.fill-zinc-400 {
    fill: #a1a1aa;
}
.fill-zinc-50 {
    fill: #fafafa;
}
.fill-zinc-500 {
    fill: #71717a;
}
.fill-zinc-600 {
    fill: #52525b;
}
.fill-zinc-700 {
    fill: #3f3f46;
}
.fill-zinc-800 {
    fill: #27272a;
}
.fill-zinc-900 {
    fill: #18181b;
}
.fill-zinc-950 {
    fill: #09090b;
}
.stroke-amber-100 {
    stroke: #fef3c7;
}
.stroke-amber-200 {
    stroke: #fde68a;
}
.stroke-amber-300 {
    stroke: #fcd34d;
}
.stroke-amber-400 {
    stroke: #fbbf24;
}
.stroke-amber-50 {
    stroke: #fffbeb;
}
.stroke-amber-500 {
    stroke: #f59e0b;
}
.stroke-amber-600 {
    stroke: #d97706;
}
.stroke-amber-700 {
    stroke: #b45309;
}
.stroke-amber-800 {
    stroke: #92400e;
}
.stroke-amber-900 {
    stroke: #78350f;
}
.stroke-amber-950 {
    stroke: #451a03;
}
.stroke-blue-100 {
    stroke: #dbeafe;
}
.stroke-blue-200 {
    stroke: #bfdbfe;
}
.stroke-blue-300 {
    stroke: #93c5fd;
}
.stroke-blue-400 {
    stroke: #60a5fa;
}
.stroke-blue-50 {
    stroke: #eff6ff;
}
.stroke-blue-500 {
    stroke: #3b82f6;
}
.stroke-blue-600 {
    stroke: #2563eb;
}
.stroke-blue-700 {
    stroke: #1d4ed8;
}
.stroke-blue-800 {
    stroke: #1e40af;
}
.stroke-blue-900 {
    stroke: #1e3a8a;
}
.stroke-blue-950 {
    stroke: #172554;
}
.stroke-cyan-100 {
    stroke: #cffafe;
}
.stroke-cyan-200 {
    stroke: #a5f3fc;
}
.stroke-cyan-300 {
    stroke: #67e8f9;
}
.stroke-cyan-400 {
    stroke: #22d3ee;
}
.stroke-cyan-50 {
    stroke: #ecfeff;
}
.stroke-cyan-500 {
    stroke: #06b6d4;
}
.stroke-cyan-600 {
    stroke: #0891b2;
}
.stroke-cyan-700 {
    stroke: #0e7490;
}
.stroke-cyan-800 {
    stroke: #155e75;
}
.stroke-cyan-900 {
    stroke: #164e63;
}
.stroke-cyan-950 {
    stroke: #083344;
}
.stroke-dark-tremor-background {
    stroke: #111827;
}
.stroke-dark-tremor-border {
    stroke: #1f2937;
}
.stroke-emerald-100 {
    stroke: #d1fae5;
}
.stroke-emerald-200 {
    stroke: #a7f3d0;
}
.stroke-emerald-300 {
    stroke: #6ee7b7;
}
.stroke-emerald-400 {
    stroke: #34d399;
}
.stroke-emerald-50 {
    stroke: #ecfdf5;
}
.stroke-emerald-500 {
    stroke: #10b981;
}
.stroke-emerald-600 {
    stroke: #059669;
}
.stroke-emerald-700 {
    stroke: #047857;
}
.stroke-emerald-800 {
    stroke: #065f46;
}
.stroke-emerald-900 {
    stroke: #064e3b;
}
.stroke-emerald-950 {
    stroke: #022c22;
}
.stroke-fuchsia-100 {
    stroke: #fae8ff;
}
.stroke-fuchsia-200 {
    stroke: #f5d0fe;
}
.stroke-fuchsia-300 {
    stroke: #f0abfc;
}
.stroke-fuchsia-400 {
    stroke: #e879f9;
}
.stroke-fuchsia-50 {
    stroke: #fdf4ff;
}
.stroke-fuchsia-500 {
    stroke: #d946ef;
}
.stroke-fuchsia-600 {
    stroke: #c026d3;
}
.stroke-fuchsia-700 {
    stroke: #a21caf;
}
.stroke-fuchsia-800 {
    stroke: #86198f;
}
.stroke-fuchsia-900 {
    stroke: #701a75;
}
.stroke-fuchsia-950 {
    stroke: #4a044e;
}
.stroke-gray-100 {
    stroke: #f3f4f6;
}
.stroke-gray-200 {
    stroke: #e5e7eb;
}
.stroke-gray-300 {
    stroke: #d1d5db;
}
.stroke-gray-400 {
    stroke: #9ca3af;
}
.stroke-gray-50 {
    stroke: #f9fafb;
}
.stroke-gray-500 {
    stroke: #6b7280;
}
.stroke-gray-600 {
    stroke: #4b5563;
}
.stroke-gray-700 {
    stroke: #374151;
}
.stroke-gray-800 {
    stroke: #1f2937;
}
.stroke-gray-900 {
    stroke: #111827;
}
.stroke-gray-950 {
    stroke: #030712;
}
.stroke-green-100 {
    stroke: #dcfce7;
}
.stroke-green-200 {
    stroke: #bbf7d0;
}
.stroke-green-300 {
    stroke: #86efac;
}
.stroke-green-400 {
    stroke: #4ade80;
}
.stroke-green-50 {
    stroke: #f0fdf4;
}
.stroke-green-500 {
    stroke: #22c55e;
}
.stroke-green-600 {
    stroke: #16a34a;
}
.stroke-green-700 {
    stroke: #15803d;
}
.stroke-green-800 {
    stroke: #166534;
}
.stroke-green-900 {
    stroke: #14532d;
}
.stroke-green-950 {
    stroke: #052e16;
}
.stroke-indigo-100 {
    stroke: #e0e7ff;
}
.stroke-indigo-200 {
    stroke: #c7d2fe;
}
.stroke-indigo-300 {
    stroke: #a5b4fc;
}
.stroke-indigo-400 {
    stroke: #818cf8;
}
.stroke-indigo-50 {
    stroke: #eef2ff;
}
.stroke-indigo-500 {
    stroke: #6366f1;
}
.stroke-indigo-600 {
    stroke: #4f46e5;
}
.stroke-indigo-700 {
    stroke: #4338ca;
}
.stroke-indigo-800 {
    stroke: #3730a3;
}
.stroke-indigo-900 {
    stroke: #312e81;
}
.stroke-indigo-950 {
    stroke: #1e1b4b;
}
.stroke-lime-100 {
    stroke: #ecfccb;
}
.stroke-lime-200 {
    stroke: #d9f99d;
}
.stroke-lime-300 {
    stroke: #bef264;
}
.stroke-lime-400 {
    stroke: #a3e635;
}
.stroke-lime-50 {
    stroke: #f7fee7;
}
.stroke-lime-500 {
    stroke: #84cc16;
}
.stroke-lime-600 {
    stroke: #65a30d;
}
.stroke-lime-700 {
    stroke: #4d7c0f;
}
.stroke-lime-800 {
    stroke: #3f6212;
}
.stroke-lime-900 {
    stroke: #365314;
}
.stroke-lime-950 {
    stroke: #1a2e05;
}
.stroke-neutral-100 {
    stroke: #f5f5f5;
}
.stroke-neutral-200 {
    stroke: #e5e5e5;
}
.stroke-neutral-300 {
    stroke: #d4d4d4;
}
.stroke-neutral-400 {
    stroke: #a3a3a3;
}
.stroke-neutral-50 {
    stroke: #fafafa;
}
.stroke-neutral-500 {
    stroke: #737373;
}
.stroke-neutral-600 {
    stroke: #525252;
}
.stroke-neutral-700 {
    stroke: #404040;
}
.stroke-neutral-800 {
    stroke: #262626;
}
.stroke-neutral-900 {
    stroke: #171717;
}
.stroke-neutral-950 {
    stroke: #0a0a0a;
}
.stroke-orange-100 {
    stroke: #ffedd5;
}
.stroke-orange-200 {
    stroke: #fed7aa;
}
.stroke-orange-300 {
    stroke: #fdba74;
}
.stroke-orange-400 {
    stroke: #fb923c;
}
.stroke-orange-50 {
    stroke: #fff7ed;
}
.stroke-orange-500 {
    stroke: #f97316;
}
.stroke-orange-600 {
    stroke: #ea580c;
}
.stroke-orange-700 {
    stroke: #c2410c;
}
.stroke-orange-800 {
    stroke: #9a3412;
}
.stroke-orange-900 {
    stroke: #7c2d12;
}
.stroke-orange-950 {
    stroke: #431407;
}
.stroke-pink-100 {
    stroke: #fce7f3;
}
.stroke-pink-200 {
    stroke: #fbcfe8;
}
.stroke-pink-300 {
    stroke: #f9a8d4;
}
.stroke-pink-400 {
    stroke: #f472b6;
}
.stroke-pink-50 {
    stroke: #fdf2f8;
}
.stroke-pink-500 {
    stroke: #ec4899;
}
.stroke-pink-600 {
    stroke: #db2777;
}
.stroke-pink-700 {
    stroke: #be185d;
}
.stroke-pink-800 {
    stroke: #9d174d;
}
.stroke-pink-900 {
    stroke: #831843;
}
.stroke-pink-950 {
    stroke: #500724;
}
.stroke-purple-100 {
    stroke: #f3e8ff;
}
.stroke-purple-200 {
    stroke: #e9d5ff;
}
.stroke-purple-300 {
    stroke: #d8b4fe;
}
.stroke-purple-400 {
    stroke: #c084fc;
}
.stroke-purple-50 {
    stroke: #faf5ff;
}
.stroke-purple-500 {
    stroke: #a855f7;
}
.stroke-purple-600 {
    stroke: #9333ea;
}
.stroke-purple-700 {
    stroke: #7e22ce;
}
.stroke-purple-800 {
    stroke: #6b21a8;
}
.stroke-purple-900 {
    stroke: #581c87;
}
.stroke-purple-950 {
    stroke: #3b0764;
}
.stroke-red-100 {
    stroke: #fee2e2;
}
.stroke-red-200 {
    stroke: #fecaca;
}
.stroke-red-300 {
    stroke: #fca5a5;
}
.stroke-red-400 {
    stroke: #f87171;
}
.stroke-red-50 {
    stroke: #fef2f2;
}
.stroke-red-500 {
    stroke: #ef4444;
}
.stroke-red-600 {
    stroke: #dc2626;
}
.stroke-red-700 {
    stroke: #b91c1c;
}
.stroke-red-800 {
    stroke: #991b1b;
}
.stroke-red-900 {
    stroke: #7f1d1d;
}
.stroke-red-950 {
    stroke: #450a0a;
}
.stroke-rose-100 {
    stroke: #ffe4e6;
}
.stroke-rose-200 {
    stroke: #fecdd3;
}
.stroke-rose-300 {
    stroke: #fda4af;
}
.stroke-rose-400 {
    stroke: #fb7185;
}
.stroke-rose-50 {
    stroke: #fff1f2;
}
.stroke-rose-500 {
    stroke: #f43f5e;
}
.stroke-rose-600 {
    stroke: #e11d48;
}
.stroke-rose-700 {
    stroke: #be123c;
}
.stroke-rose-800 {
    stroke: #9f1239;
}
.stroke-rose-900 {
    stroke: #881337;
}
.stroke-rose-950 {
    stroke: #4c0519;
}
.stroke-sky-100 {
    stroke: #e0f2fe;
}
.stroke-sky-200 {
    stroke: #bae6fd;
}
.stroke-sky-300 {
    stroke: #7dd3fc;
}
.stroke-sky-400 {
    stroke: #38bdf8;
}
.stroke-sky-50 {
    stroke: #f0f9ff;
}
.stroke-sky-500 {
    stroke: #0ea5e9;
}
.stroke-sky-600 {
    stroke: #0284c7;
}
.stroke-sky-700 {
    stroke: #0369a1;
}
.stroke-sky-800 {
    stroke: #075985;
}
.stroke-sky-900 {
    stroke: #0c4a6e;
}
.stroke-sky-950 {
    stroke: #082f49;
}
.stroke-slate-100 {
    stroke: #f1f5f9;
}
.stroke-slate-200 {
    stroke: #e2e8f0;
}
.stroke-slate-300 {
    stroke: #cbd5e1;
}
.stroke-slate-400 {
    stroke: #94a3b8;
}
.stroke-slate-50 {
    stroke: #f8fafc;
}
.stroke-slate-500 {
    stroke: #64748b;
}
.stroke-slate-600 {
    stroke: #475569;
}
.stroke-slate-700 {
    stroke: #334155;
}
.stroke-slate-800 {
    stroke: #1e293b;
}
.stroke-slate-900 {
    stroke: #0f172a;
}
.stroke-slate-950 {
    stroke: #020617;
}
.stroke-stone-100 {
    stroke: #f5f5f4;
}
.stroke-stone-200 {
    stroke: #e7e5e4;
}
.stroke-stone-300 {
    stroke: #d6d3d1;
}
.stroke-stone-400 {
    stroke: #a8a29e;
}
.stroke-stone-50 {
    stroke: #fafaf9;
}
.stroke-stone-500 {
    stroke: #78716c;
}
.stroke-stone-600 {
    stroke: #57534e;
}
.stroke-stone-700 {
    stroke: #44403c;
}
.stroke-stone-800 {
    stroke: #292524;
}
.stroke-stone-900 {
    stroke: #1c1917;
}
.stroke-stone-950 {
    stroke: #0c0a09;
}
.stroke-teal-100 {
    stroke: #ccfbf1;
}
.stroke-teal-200 {
    stroke: #99f6e4;
}
.stroke-teal-300 {
    stroke: #5eead4;
}
.stroke-teal-400 {
    stroke: #2dd4bf;
}
.stroke-teal-50 {
    stroke: #f0fdfa;
}
.stroke-teal-500 {
    stroke: #14b8a6;
}
.stroke-teal-600 {
    stroke: #0d9488;
}
.stroke-teal-700 {
    stroke: #0f766e;
}
.stroke-teal-800 {
    stroke: #115e59;
}
.stroke-teal-900 {
    stroke: #134e4a;
}
.stroke-teal-950 {
    stroke: #042f2e;
}
.stroke-tremor-background {
    stroke: #fff;
}
.stroke-tremor-border {
    stroke: #e5e7eb;
}
.stroke-tremor-brand {
    stroke: #1677ff;
}
.stroke-tremor-brand-muted\/50 {
    stroke: rgb(105 177 255 / 0.5);
}
.stroke-violet-100 {
    stroke: #ede9fe;
}
.stroke-violet-200 {
    stroke: #ddd6fe;
}
.stroke-violet-300 {
    stroke: #c4b5fd;
}
.stroke-violet-400 {
    stroke: #a78bfa;
}
.stroke-violet-50 {
    stroke: #f5f3ff;
}
.stroke-violet-500 {
    stroke: #8b5cf6;
}
.stroke-violet-600 {
    stroke: #7c3aed;
}
.stroke-violet-700 {
    stroke: #6d28d9;
}
.stroke-violet-800 {
    stroke: #5b21b6;
}
.stroke-violet-900 {
    stroke: #4c1d95;
}
.stroke-violet-950 {
    stroke: #2e1065;
}
.stroke-yellow-100 {
    stroke: #fef9c3;
}
.stroke-yellow-200 {
    stroke: #fef08a;
}
.stroke-yellow-300 {
    stroke: #fde047;
}
.stroke-yellow-400 {
    stroke: #facc15;
}
.stroke-yellow-50 {
    stroke: #fefce8;
}
.stroke-yellow-500 {
    stroke: #eab308;
}
.stroke-yellow-600 {
    stroke: #ca8a04;
}
.stroke-yellow-700 {
    stroke: #a16207;
}
.stroke-yellow-800 {
    stroke: #854d0e;
}
.stroke-yellow-900 {
    stroke: #713f12;
}
.stroke-yellow-950 {
    stroke: #422006;
}
.stroke-zinc-100 {
    stroke: #f4f4f5;
}
.stroke-zinc-200 {
    stroke: #e4e4e7;
}
.stroke-zinc-300 {
    stroke: #d4d4d8;
}
.stroke-zinc-400 {
    stroke: #a1a1aa;
}
.stroke-zinc-50 {
    stroke: #fafafa;
}
.stroke-zinc-500 {
    stroke: #71717a;
}
.stroke-zinc-600 {
    stroke: #52525b;
}
.stroke-zinc-700 {
    stroke: #3f3f46;
}
.stroke-zinc-800 {
    stroke: #27272a;
}
.stroke-zinc-900 {
    stroke: #18181b;
}
.stroke-zinc-950 {
    stroke: #09090b;
}
.stroke-1 {
    stroke-width: 1;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.\!p-0 {
    padding: 0px !important;
}
.\!p-1 {
    padding: 0.25rem !important;
}
.\!p-3 {
    padding: 0.75rem !important;
}
.p-0 {
    padding: 0px;
}
.p-0\.5 {
    padding: 0.125rem;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-2\.5 {
    padding: 0.625rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-\[11px\] {
    padding: 11px;
}
.\!px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.\!px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.\!py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-\[11px\] {
    padding-left: 11px;
    padding-right: 11px;
}
.px-\[7px\] {
    padding-left: 7px;
    padding-right: 7px;
}
.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}
.py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py-\[14px\] {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py-\[1px\] {
    padding-top: 1px;
    padding-bottom: 1px;
}
.\!pt-0 {
    padding-top: 0px !important;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pl-10 {
    padding-left: 2.5rem;
}
.pl-11 {
    padding-left: 2.75rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-2\.5 {
    padding-left: 0.625rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pr-1 {
    padding-right: 0.25rem;
}
.pr-1\.5 {
    padding-right: 0.375rem;
}
.pr-12 {
    padding-right: 3rem;
}
.pr-14 {
    padding-right: 3.5rem;
}
.pr-16 {
    padding-right: 4rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-2\.5 {
    padding-right: 0.625rem;
}
.pr-3 {
    padding-right: 0.75rem;
}
.pr-4 {
    padding-right: 1rem;
}
.pr-8 {
    padding-right: 2rem;
}
.pt-0 {
    padding-top: 0px;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-4 {
    padding-top: 1rem;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-start {
    text-align: start;
}
.text-end {
    text-align: end;
}
.align-top {
    vertical-align: top;
}
.align-middle {
    vertical-align: middle;
}
.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.font-sans {
    font-family: var(--font-inter);
}
.\!text-tremor-label {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}
.\!text-xs {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}
.text-\[12px\] {
    font-size: 12px;
}
.text-\[14px\] {
    font-size: 14px;
}
.text-\[16px\] {
    font-size: 16px;
}
.text-\[32px\] {
    font-size: 32px;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-tremor-default {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-tremor-label {
    font-size: 0.75rem;
    line-height: 1rem;
}
.text-tremor-metric {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-tremor-title {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-\[500\] {
    font-weight: 500;
}
.font-\[600\] {
    font-weight: 600;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 600;
}
.capitalize {
    text-transform: capitalize;
}
.italic {
    font-style: italic;
}
.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-\[18px\] {
    line-height: 18px;
}
.leading-\[20px\] {
    line-height: 20px;
}
.leading-\[22px\] {
    line-height: 22px;
}
.leading-none {
    line-height: 1;
}
.tracking-\[0\.08em\] {
    letter-spacing: 0.08em;
}
.\!text-blue-600 {
    --tw-text-opacity: 1 !important;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1)) !important;
}
.\!text-inherit {
    color: inherit !important;
}
.\!text-tremor-content-subtle {
    --tw-text-opacity: 1 !important;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1)) !important;
}
.text-\[\#1677FF\] {
    --tw-text-opacity: 1;
    color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}
.text-\[\#1C2C3D\] {
    --tw-text-opacity: 1;
    color: rgb(28 44 61 / var(--tw-text-opacity, 1));
}
.text-\[\#237804\] {
    --tw-text-opacity: 1;
    color: rgb(35 120 4 / var(--tw-text-opacity, 1));
}
.text-\[\#586673\] {
    --tw-text-opacity: 1;
    color: rgb(88 102 115 / var(--tw-text-opacity, 1));
}
.text-\[\#722ED1\] {
    --tw-text-opacity: 1;
    color: rgb(114 46 209 / var(--tw-text-opacity, 1));
}
.text-\[\#758391\] {
    --tw-text-opacity: 1;
    color: rgb(117 131 145 / var(--tw-text-opacity, 1));
}
.text-\[\#BDC7D1\] {
    --tw-text-opacity: 1;
    color: rgb(189 199 209 / var(--tw-text-opacity, 1));
}
.text-\[\#D61010\] {
    --tw-text-opacity: 1;
    color: rgb(214 16 16 / var(--tw-text-opacity, 1));
}
.text-\[\#FA541C\] {
    --tw-text-opacity: 1;
    color: rgb(250 84 28 / var(--tw-text-opacity, 1));
}
.text-\[\#FF1493\] {
    --tw-text-opacity: 1;
    color: rgb(255 20 147 / var(--tw-text-opacity, 1));
}
.text-\[\#d1d5db\]\/15 {
    color: rgb(209 213 219 / 0.15);
}
.text-amber-100 {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity, 1));
}
.text-amber-200 {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity, 1));
}
.text-amber-300 {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity, 1));
}
.text-amber-400 {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}
.text-amber-50 {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity, 1));
}
.text-amber-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}
.text-amber-600 {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}
.text-amber-700 {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}
.text-amber-800 {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}
.text-amber-900 {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
.text-amber-950 {
    --tw-text-opacity: 1;
    color: rgb(69 26 3 / var(--tw-text-opacity, 1));
}
.text-blue-100 {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}
.text-blue-200 {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}
.text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}
.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}
.text-blue-50 {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity, 1));
}
.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
.text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
.text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}
.text-blue-900 {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
.text-blue-950 {
    --tw-text-opacity: 1;
    color: rgb(23 37 84 / var(--tw-text-opacity, 1));
}
.text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}
.text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}
.text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}
.text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}
.text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity, 1));
}
.text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}
.text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}
.text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}
.text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity, 1));
}
.text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity, 1));
}
.text-cyan-950 {
    --tw-text-opacity: 1;
    color: rgb(8 51 68 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-brand {
    --tw-text-opacity: 1;
    color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-brand-emphasis {
    --tw-text-opacity: 1;
    color: rgb(9 88 217 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-brand-inverted {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-content {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-content-emphasis {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
.text-dark-tremor-content-subtle {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.text-emerald-100 {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity, 1));
}
.text-emerald-200 {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity, 1));
}
.text-emerald-300 {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity, 1));
}
.text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}
.text-emerald-50 {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity, 1));
}
.text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}
.text-emerald-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}
.text-emerald-700 {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}
.text-emerald-800 {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity, 1));
}
.text-emerald-900 {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity, 1));
}
.text-emerald-950 {
    --tw-text-opacity: 1;
    color: rgb(2 44 34 / var(--tw-text-opacity, 1));
}
.text-fuchsia-100 {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity, 1));
}
.text-fuchsia-200 {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity, 1));
}
.text-fuchsia-300 {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity, 1));
}
.text-fuchsia-400 {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}
.text-fuchsia-50 {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity, 1));
}
.text-fuchsia-500 {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}
.text-fuchsia-600 {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity, 1));
}
.text-fuchsia-700 {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity, 1));
}
.text-fuchsia-800 {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity, 1));
}
.text-fuchsia-900 {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity, 1));
}
.text-fuchsia-950 {
    --tw-text-opacity: 1;
    color: rgb(74 4 78 / var(--tw-text-opacity, 1));
}
.text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}
.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.text-gray-50 {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}
.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.text-gray-950 {
    --tw-text-opacity: 1;
    color: rgb(3 7 18 / var(--tw-text-opacity, 1));
}
.text-green-100 {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity, 1));
}
.text-green-200 {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity, 1));
}
.text-green-300 {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}
.text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}
.text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity, 1));
}
.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}
.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
.text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}
.text-green-900 {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.text-green-950 {
    --tw-text-opacity: 1;
    color: rgb(5 46 22 / var(--tw-text-opacity, 1));
}
.text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}
.text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}
.text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}
.text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}
.text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}
.text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}
.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}
.text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}
.text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}
.text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity, 1));
}
.text-indigo-950 {
    --tw-text-opacity: 1;
    color: rgb(30 27 75 / var(--tw-text-opacity, 1));
}
.text-inherit {
    color: inherit;
}
.text-lime-100 {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity, 1));
}
.text-lime-200 {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity, 1));
}
.text-lime-300 {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity, 1));
}
.text-lime-400 {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}
.text-lime-50 {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity, 1));
}
.text-lime-500 {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}
.text-lime-600 {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}
.text-lime-700 {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity, 1));
}
.text-lime-800 {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}
.text-lime-900 {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}
.text-lime-950 {
    --tw-text-opacity: 1;
    color: rgb(26 46 5 / var(--tw-text-opacity, 1));
}
.text-neutral-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity, 1));
}
.text-neutral-200 {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity, 1));
}
.text-neutral-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity, 1));
}
.text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity, 1));
}
.text-neutral-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}
.text-neutral-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}
.text-neutral-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity, 1));
}
.text-neutral-800 {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}
.text-neutral-900 {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity, 1));
}
.text-neutral-950 {
    --tw-text-opacity: 1;
    color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
.text-orange-100 {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity, 1));
}
.text-orange-200 {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity, 1));
}
.text-orange-300 {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity, 1));
}
.text-orange-400 {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}
.text-orange-50 {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity, 1));
}
.text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}
.text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}
.text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}
.text-orange-800 {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity, 1));
}
.text-orange-900 {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}
.text-orange-950 {
    --tw-text-opacity: 1;
    color: rgb(67 20 7 / var(--tw-text-opacity, 1));
}
.text-pink-100 {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity, 1));
}
.text-pink-200 {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity, 1));
}
.text-pink-300 {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity, 1));
}
.text-pink-400 {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}
.text-pink-50 {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}
.text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}
.text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}
.text-pink-700 {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity, 1));
}
.text-pink-800 {
    --tw-text-opacity: 1;
    color: rgb(157 23 77 / var(--tw-text-opacity, 1));
}
.text-pink-900 {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity, 1));
}
.text-pink-950 {
    --tw-text-opacity: 1;
    color: rgb(80 7 36 / var(--tw-text-opacity, 1));
}
.text-purple-100 {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity, 1));
}
.text-purple-200 {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity, 1));
}
.text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}
.text-purple-400 {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}
.text-purple-50 {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity, 1));
}
.text-purple-500 {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}
.text-purple-600 {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
.text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}
.text-purple-800 {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}
.text-purple-900 {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
.text-purple-950 {
    --tw-text-opacity: 1;
    color: rgb(59 7 100 / var(--tw-text-opacity, 1));
}
.text-red-100 {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity, 1));
}
.text-red-200 {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity, 1));
}
.text-red-300 {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}
.text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}
.text-red-50 {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity, 1));
}
.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}
.text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}
.text-red-900 {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}
.text-red-950 {
    --tw-text-opacity: 1;
    color: rgb(69 10 10 / var(--tw-text-opacity, 1));
}
.text-rose-100 {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity, 1));
}
.text-rose-200 {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity, 1));
}
.text-rose-300 {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity, 1));
}
.text-rose-400 {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}
.text-rose-50 {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity, 1));
}
.text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}
.text-rose-600 {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity, 1));
}
.text-rose-700 {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}
.text-rose-800 {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity, 1));
}
.text-rose-900 {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity, 1));
}
.text-rose-950 {
    --tw-text-opacity: 1;
    color: rgb(76 5 25 / var(--tw-text-opacity, 1));
}
.text-sky-100 {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}
.text-sky-200 {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}
.text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity, 1));
}
.text-sky-400 {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}
.text-sky-50 {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}
.text-sky-500 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}
.text-sky-600 {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}
.text-sky-700 {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}
.text-sky-800 {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}
.text-sky-900 {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}
.text-sky-950 {
    --tw-text-opacity: 1;
    color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}
.text-slate-100 {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}
.text-slate-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}
.text-slate-300 {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}
.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}
.text-slate-50 {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}
.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
.text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}
.text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}
.text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
.text-slate-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}
.text-slate-950 {
    --tw-text-opacity: 1;
    color: rgb(2 6 23 / var(--tw-text-opacity, 1));
}
.text-stone-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity, 1));
}
.text-stone-200 {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity, 1));
}
.text-stone-300 {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity, 1));
}
.text-stone-400 {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity, 1));
}
.text-stone-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity, 1));
}
.text-stone-500 {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}
.text-stone-600 {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}
.text-stone-700 {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity, 1));
}
.text-stone-800 {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}
.text-stone-900 {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}
.text-stone-950 {
    --tw-text-opacity: 1;
    color: rgb(12 10 9 / var(--tw-text-opacity, 1));
}
.text-teal-100 {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity, 1));
}
.text-teal-200 {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}
.text-teal-300 {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity, 1));
}
.text-teal-400 {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}
.text-teal-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity, 1));
}
.text-teal-500 {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}
.text-teal-600 {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}
.text-teal-700 {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}
.text-teal-800 {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity, 1));
}
.text-teal-900 {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity, 1));
}
.text-teal-950 {
    --tw-text-opacity: 1;
    color: rgb(4 47 46 / var(--tw-text-opacity, 1));
}
.text-transparent {
    color: transparent;
}
.text-tremor-brand {
    --tw-text-opacity: 1;
    color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}
.text-tremor-brand-emphasis {
    --tw-text-opacity: 1;
    color: rgb(9 88 217 / var(--tw-text-opacity, 1));
}
.text-tremor-brand-inverted {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-tremor-content {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-tremor-content-emphasis {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-tremor-content-strong {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.text-tremor-content-subtle {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.text-violet-100 {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity, 1));
}
.text-violet-200 {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity, 1));
}
.text-violet-300 {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity, 1));
}
.text-violet-400 {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}
.text-violet-50 {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity, 1));
}
.text-violet-500 {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}
.text-violet-600 {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}
.text-violet-700 {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}
.text-violet-800 {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}
.text-violet-900 {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity, 1));
}
.text-violet-950 {
    --tw-text-opacity: 1;
    color: rgb(46 16 101 / var(--tw-text-opacity, 1));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity, 1));
}
.text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity, 1));
}
.text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity, 1));
}
.text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}
.text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity, 1));
}
.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}
.text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}
.text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}
.text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}
.text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity, 1));
}
.text-yellow-950 {
    --tw-text-opacity: 1;
    color: rgb(66 32 6 / var(--tw-text-opacity, 1));
}
.text-zinc-100 {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity, 1));
}
.text-zinc-200 {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity, 1));
}
.text-zinc-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity, 1));
}
.text-zinc-400 {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity, 1));
}
.text-zinc-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.text-zinc-500 {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}
.text-zinc-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity, 1));
}
.text-zinc-700 {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity, 1));
}
.text-zinc-800 {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity, 1));
}
.text-zinc-900 {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1));
}
.text-zinc-950 {
    --tw-text-opacity: 1;
    color: rgb(9 9 11 / var(--tw-text-opacity, 1));
}
.\!underline {
    text-decoration-line: underline !important;
}
.underline {
    text-decoration-line: underline;
}
.line-through {
    text-decoration-line: line-through;
}
.\!underline-offset-2 {
    text-underline-offset: 2px !important;
}
.underline-offset-1 {
    text-underline-offset: 1px;
}
.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.accent-dark-tremor-brand {
    accent-color: #1677ff;
}
.accent-tremor-brand {
    accent-color: #1677ff;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.opacity-20 {
    opacity: 0.2;
}
.opacity-30 {
    opacity: 0.3;
}
.opacity-40 {
    opacity: 0.4;
}
.opacity-50 {
    opacity: 0.5;
}
.\!shadow-none {
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[15px_0px_80px_0px_rgba\(214\2c 222\2c 230\2c 0\.5\)\] {
    --tw-shadow: 15px 0px 80px 0px rgba(214,222,230,0.5);
    --tw-shadow-colored: 15px 0px 80px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-dark-tremor-card {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-dark-tremor-input {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-tremor-card {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-tremor-dropdown {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-tremor-input {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline {
    outline-style: solid;
}
.outline-tremor-brand {
    outline-color: #1677ff;
}
.ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-inset {
    --tw-ring-inset: inset;
}
.ring-amber-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity, 1));
}
.ring-amber-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity, 1));
}
.ring-amber-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity, 1));
}
.ring-amber-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity, 1));
}
.ring-amber-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity, 1));
}
.ring-amber-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity, 1));
}
.ring-amber-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity, 1));
}
.ring-amber-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity, 1));
}
.ring-amber-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity, 1));
}
.ring-amber-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity, 1));
}
.ring-amber-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(69 26 3 / var(--tw-ring-opacity, 1));
}
.ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity, 1));
}
.ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity, 1));
}
.ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity, 1));
}
.ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity, 1));
}
.ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity, 1));
}
.ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
}
.ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity, 1));
}
.ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
}
.ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity, 1));
}
.ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity, 1));
}
.ring-blue-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 37 84 / var(--tw-ring-opacity, 1));
}
.ring-cyan-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity, 1));
}
.ring-cyan-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity, 1));
}
.ring-cyan-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity, 1));
}
.ring-cyan-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity, 1));
}
.ring-cyan-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity, 1));
}
.ring-cyan-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity, 1));
}
.ring-cyan-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity, 1));
}
.ring-cyan-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity, 1));
}
.ring-cyan-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity, 1));
}
.ring-cyan-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity, 1));
}
.ring-cyan-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 51 68 / var(--tw-ring-opacity, 1));
}
.ring-dark-tremor-ring {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity, 1));
}
.ring-emerald-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity, 1));
}
.ring-emerald-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity, 1));
}
.ring-emerald-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity, 1));
}
.ring-emerald-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity, 1));
}
.ring-emerald-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity, 1));
}
.ring-emerald-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity, 1));
}
.ring-emerald-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity, 1));
}
.ring-emerald-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity, 1));
}
.ring-emerald-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity, 1));
}
.ring-emerald-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity, 1));
}
.ring-emerald-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 44 34 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity, 1));
}
.ring-fuchsia-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 4 78 / var(--tw-ring-opacity, 1));
}
.ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity, 1));
}
.ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
}
.ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}
.ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity, 1));
}
.ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity, 1));
}
.ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity, 1));
}
.ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity, 1));
}
.ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity, 1));
}
.ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity, 1));
}
.ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity, 1));
}
.ring-gray-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 7 18 / var(--tw-ring-opacity, 1));
}
.ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity, 1));
}
.ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity, 1));
}
.ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity, 1));
}
.ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity, 1));
}
.ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity, 1));
}
.ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity, 1));
}
.ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity, 1));
}
.ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity, 1));
}
.ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity, 1));
}
.ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity, 1));
}
.ring-green-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(5 46 22 / var(--tw-ring-opacity, 1));
}
.ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity, 1));
}
.ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity, 1));
}
.ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity, 1));
}
.ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity, 1));
}
.ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity, 1));
}
.ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity, 1));
}
.ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity, 1));
}
.ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity, 1));
}
.ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity, 1));
}
.ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity, 1));
}
.ring-indigo-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 27 75 / var(--tw-ring-opacity, 1));
}
.ring-lime-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity, 1));
}
.ring-lime-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity, 1));
}
.ring-lime-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity, 1));
}
.ring-lime-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity, 1));
}
.ring-lime-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity, 1));
}
.ring-lime-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity, 1));
}
.ring-lime-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity, 1));
}
.ring-lime-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity, 1));
}
.ring-lime-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity, 1));
}
.ring-lime-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity, 1));
}
.ring-lime-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(26 46 5 / var(--tw-ring-opacity, 1));
}
.ring-neutral-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity, 1));
}
.ring-neutral-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity, 1));
}
.ring-neutral-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity, 1));
}
.ring-neutral-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity, 1));
}
.ring-neutral-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity, 1));
}
.ring-neutral-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity, 1));
}
.ring-neutral-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity, 1));
}
.ring-neutral-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity, 1));
}
.ring-neutral-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity, 1));
}
.ring-neutral-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity, 1));
}
.ring-neutral-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(10 10 10 / var(--tw-ring-opacity, 1));
}
.ring-orange-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity, 1));
}
.ring-orange-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity, 1));
}
.ring-orange-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity, 1));
}
.ring-orange-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity, 1));
}
.ring-orange-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity, 1));
}
.ring-orange-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity, 1));
}
.ring-orange-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity, 1));
}
.ring-orange-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity, 1));
}
.ring-orange-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity, 1));
}
.ring-orange-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity, 1));
}
.ring-orange-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(67 20 7 / var(--tw-ring-opacity, 1));
}
.ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity, 1));
}
.ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity, 1));
}
.ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity, 1));
}
.ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity, 1));
}
.ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity, 1));
}
.ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity, 1));
}
.ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity, 1));
}
.ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity, 1));
}
.ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(157 23 77 / var(--tw-ring-opacity, 1));
}
.ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity, 1));
}
.ring-pink-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(80 7 36 / var(--tw-ring-opacity, 1));
}
.ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity, 1));
}
.ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity, 1));
}
.ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity, 1));
}
.ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity, 1));
}
.ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity, 1));
}
.ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity, 1));
}
.ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity, 1));
}
.ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity, 1));
}
.ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity, 1));
}
.ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity, 1));
}
.ring-purple-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 7 100 / var(--tw-ring-opacity, 1));
}
.ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity, 1));
}
.ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity, 1));
}
.ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity, 1));
}
.ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity, 1));
}
.ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity, 1));
}
.ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity, 1));
}
.ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity, 1));
}
.ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity, 1));
}
.ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity, 1));
}
.ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity, 1));
}
.ring-red-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(69 10 10 / var(--tw-ring-opacity, 1));
}
.ring-rose-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity, 1));
}
.ring-rose-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity, 1));
}
.ring-rose-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity, 1));
}
.ring-rose-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity, 1));
}
.ring-rose-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity, 1));
}
.ring-rose-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity, 1));
}
.ring-rose-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity, 1));
}
.ring-rose-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity, 1));
}
.ring-rose-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity, 1));
}
.ring-rose-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity, 1));
}
.ring-rose-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 5 25 / var(--tw-ring-opacity, 1));
}
.ring-sky-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity, 1));
}
.ring-sky-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity, 1));
}
.ring-sky-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity, 1));
}
.ring-sky-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity, 1));
}
.ring-sky-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity, 1));
}
.ring-sky-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity, 1));
}
.ring-sky-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity, 1));
}
.ring-sky-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity, 1));
}
.ring-sky-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity, 1));
}
.ring-sky-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity, 1));
}
.ring-sky-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(8 47 73 / var(--tw-ring-opacity, 1));
}
.ring-slate-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity, 1));
}
.ring-slate-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity, 1));
}
.ring-slate-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity, 1));
}
.ring-slate-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity, 1));
}
.ring-slate-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity, 1));
}
.ring-slate-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity, 1));
}
.ring-slate-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity, 1));
}
.ring-slate-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity, 1));
}
.ring-slate-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity, 1));
}
.ring-slate-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity, 1));
}
.ring-slate-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(2 6 23 / var(--tw-ring-opacity, 1));
}
.ring-stone-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity, 1));
}
.ring-stone-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity, 1));
}
.ring-stone-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity, 1));
}
.ring-stone-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity, 1));
}
.ring-stone-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity, 1));
}
.ring-stone-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity, 1));
}
.ring-stone-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity, 1));
}
.ring-stone-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity, 1));
}
.ring-stone-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity, 1));
}
.ring-stone-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity, 1));
}
.ring-stone-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(12 10 9 / var(--tw-ring-opacity, 1));
}
.ring-teal-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity, 1));
}
.ring-teal-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity, 1));
}
.ring-teal-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity, 1));
}
.ring-teal-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity, 1));
}
.ring-teal-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity, 1));
}
.ring-teal-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity, 1));
}
.ring-teal-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity, 1));
}
.ring-teal-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity, 1));
}
.ring-teal-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity, 1));
}
.ring-teal-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity, 1));
}
.ring-teal-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(4 47 46 / var(--tw-ring-opacity, 1));
}
.ring-tremor-brand-inverted {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}
.ring-tremor-brand-muted {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(105 177 255 / var(--tw-ring-opacity, 1));
}
.ring-tremor-brand\/20 {
    --tw-ring-color: rgb(22 119 255 / 0.2);
}
.ring-tremor-ring {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity, 1));
}
.ring-violet-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity, 1));
}
.ring-violet-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity, 1));
}
.ring-violet-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity, 1));
}
.ring-violet-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity, 1));
}
.ring-violet-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity, 1));
}
.ring-violet-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity, 1));
}
.ring-violet-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity, 1));
}
.ring-violet-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity, 1));
}
.ring-violet-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity, 1));
}
.ring-violet-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity, 1));
}
.ring-violet-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(46 16 101 / var(--tw-ring-opacity, 1));
}
.ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity, 1));
}
.ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity, 1));
}
.ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity, 1));
}
.ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity, 1));
}
.ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity, 1));
}
.ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity, 1));
}
.ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity, 1));
}
.ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity, 1));
}
.ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity, 1));
}
.ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity, 1));
}
.ring-yellow-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(66 32 6 / var(--tw-ring-opacity, 1));
}
.ring-zinc-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity, 1));
}
.ring-zinc-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity, 1));
}
.ring-zinc-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity, 1));
}
.ring-zinc-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity, 1));
}
.ring-zinc-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity, 1));
}
.ring-zinc-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity, 1));
}
.ring-zinc-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity, 1));
}
.ring-zinc-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity, 1));
}
.ring-zinc-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity, 1));
}
.ring-zinc-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity, 1));
}
.ring-zinc-950 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(9 9 11 / var(--tw-ring-opacity, 1));
}
.ring-opacity-20 {
    --tw-ring-opacity: 0.2;
}
.ring-opacity-40 {
    --tw-ring-opacity: 0.4;
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-100 {
    transition-duration: 100ms;
}
.duration-150 {
    transition-duration: 150ms;
}
.duration-200 {
    transition-duration: 200ms;
}
.duration-300 {
    transition-duration: 300ms;
}
.duration-500 {
    transition-duration: 500ms;
}
.duration-75 {
    transition-duration: 75ms;
}
.duration-\[inherit\] {
    transition-duration: inherit;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-linear {
    transition-timing-function: linear;
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.\@container {
    container-type: inline-size;
}
.\[--anchor-gap\:4px\] {
    --anchor-gap: 4px;
}
.\[appearance\:textfield\] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}
.\[scrollbar-width\:none\] {
    scrollbar-width: none;
}

html,
body {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.dark-mode {
    background-color: #141414;
}

.light-mode {
    background-color: #ffffff;
    color: #000;

    .next-error-h1 {
        border-right: 1px solid #000;
    }
}

.evalautionContainer {
    margin-bottom: 200px;
}

.mdxSlider img {
    width: 100%;
}

.testset-column {
    cursor: pointer;
}

.testset-column a {
    color: inherit;
}

.editor-input > p {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        box-shadow: 0 0 0 7px rgba(255, 82, 82, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.hover-button-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.ant-table-row:hover .hover-button-wrapper {
    opacity: 1;
}

.ant-tooltip-inner {
    max-height: 300px;
    overflow-y: auto;
}

@media screen and (max-width: 1572px) {
    .evalaution-title {
        min-height: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .evalaution-title {
        min-height: 100px;
    }
}

@media screen and (max-width: 848px) {
    .evalaution-title {
        min-height: 120px;
    }
}

/* It removes antd tabs transition to fix lag when using type="editable-card" */
.editable-card.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    transition: none;
}

/* It aligns the settings icon in the table column  */
.ant-table-cell-fix-right-first svg {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

/* Custom loading circle for app creation proccess  */
.loading-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
}
.loading-circle path {
    stroke-width: 2;
    animation: colorChange 1.6s infinite;
    transition:
        stroke 0.4s ease,
        stroke-width 0.4s ease;
    transition-duration: 0.5s;
}

.loading-circle path:nth-child(1) {
    animation-delay: 0s;
}
.loading-circle path:nth-child(2) {
    animation-delay: 0.4s;
}
.loading-circle path:nth-child(3) {
    animation-delay: 0.8s;
}
.loading-circle path:nth-child(4) {
    animation-delay: 1.2s;
}
@keyframes colorChange {
    0%,
    25% {
        stroke: #36cfc9;
    }
    26%,
    100% {
        stroke: #d6dee6;
    }
}
.\*\:w-full > * {
    width: 100%;
}
.\*\:\!select-none > * {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
            user-select: none !important;
}
.\*\:whitespace-nowrap > * {
    white-space: nowrap;
}
.\*\:\!border-none > * {
    border-style: none !important;
}
.\*\:\!stroke-\[\#36cfc9\] > * {
    stroke: #36cfc9 !important;
}
.\*\:\!shadow-\[0px_0px_10px_0px_\#B2F8FF\] > * {
    --tw-shadow: 0px 0px 10px 0px #B2F8FF !important;
    --tw-shadow-colored: 0px 0px 10px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.placeholder\:text-red-500::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.placeholder\:text-red-500::placeholder {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.placeholder\:text-tremor-content::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.placeholder\:text-tremor-content::placeholder {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.placeholder\:text-tremor-content-subtle::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.placeholder\:text-tremor-content-subtle::placeholder {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.first\:rounded-l-\[4px\]:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.last\:rounded-r-\[4px\]:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.focus-within\:relative:focus-within {
    position: relative;
}
.hover\:border-b-2:hover {
    border-bottom-width: 2px;
}
.hover\:\!border-\[\#BDC7D1\]:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgb(189 199 209 / var(--tw-border-opacity, 1)) !important;
}
.hover\:border-\[\#394857\]:hover {
    --tw-border-opacity: 1;
    border-color: rgb(57 72 87 / var(--tw-border-opacity, 1));
}
.hover\:border-\[394857\]:hover {
    border-color: 394857;
}
.hover\:border-\[transparent\]:hover {
    border-color: transparent;
}
.hover\:border-amber-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity, 1));
}
.hover\:border-amber-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(69 26 3 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}
.hover\:border-blue-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(23 37 84 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity, 1));
}
.hover\:border-cyan-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(8 51 68 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity, 1));
}
.hover\:border-emerald-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 44 34 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity, 1));
}
.hover\:border-fuchsia-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(74 4 78 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}
.hover\:border-gray-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(3 7 18 / var(--tw-border-opacity, 1));
}
.hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity, 1));
}
.hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}
.hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}
.hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}
.hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity, 1));
}
.hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}
.hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}
.hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}
.hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}
.hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}
.hover\:border-green-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(5 46 22 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity, 1));
}
.hover\:border-indigo-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 27 75 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity, 1));
}
.hover\:border-lime-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(26 46 5 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity, 1));
}
.hover\:border-neutral-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(10 10 10 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity, 1));
}
.hover\:border-orange-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(67 20 7 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(157 23 77 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity, 1));
}
.hover\:border-pink-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(80 7 36 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity, 1));
}
.hover\:border-purple-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(59 7 100 / var(--tw-border-opacity, 1));
}
.hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity, 1));
}
.hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}
.hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}
.hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}
.hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity, 1));
}
.hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
.hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}
.hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}
.hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
}
.hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity, 1));
}
.hover\:border-red-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(69 10 10 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity, 1));
}
.hover\:border-rose-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(76 5 25 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}
.hover\:border-sky-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity, 1));
}
.hover\:border-slate-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 6 23 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity, 1));
}
.hover\:border-stone-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(12 10 9 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity, 1));
}
.hover\:border-teal-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(4 47 46 / var(--tw-border-opacity, 1));
}
.hover\:border-tremor-brand-emphasis:hover {
    --tw-border-opacity: 1;
    border-color: rgb(9 88 217 / var(--tw-border-opacity, 1));
}
.hover\:border-tremor-content:hover {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity, 1));
}
.hover\:border-violet-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(46 16 101 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity, 1));
}
.hover\:border-yellow-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(66 32 6 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-100:hover {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-50:hover {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-500:hover {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-600:hover {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-800:hover {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-900:hover {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity, 1));
}
.hover\:border-zinc-950:hover {
    --tw-border-opacity: 1;
    border-color: rgb(9 9 11 / var(--tw-border-opacity, 1));
}
.hover\:\!bg-\[rgba\(5\2c 23\2c 41\2c 0\.15\)\]:hover {
    background-color: rgba(5,23,41,0.15) !important;
}
.hover\:bg-\[\#f5f7fa\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 247 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity, 1));
}
.hover\:bg-amber-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(69 26 3 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}
.hover\:bg-blue-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(23 37 84 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity, 1));
}
.hover\:bg-cyan-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(8 51 68 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity, 1));
}
.hover\:bg-emerald-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(2 44 34 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity, 1));
}
.hover\:bg-fuchsia-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(74 4 78 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}
.hover\:bg-gray-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}
.hover\:bg-green-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(5 46 22 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity, 1));
}
.hover\:bg-indigo-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 27 75 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity, 1));
}
.hover\:bg-lime-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(26 46 5 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
}
.hover\:bg-neutral-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(10 10 10 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity, 1));
}
.hover\:bg-orange-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 20 7 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(157 23 77 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity, 1));
}
.hover\:bg-pink-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(80 7 36 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}
.hover\:bg-purple-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 7 100 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity, 1));
}
.hover\:bg-red-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(69 10 10 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity, 1));
}
.hover\:bg-rose-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(76 5 25 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}
.hover\:bg-sky-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity, 1));
}
.hover\:bg-slate-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}
.hover\:bg-stone-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(12 10 9 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity, 1));
}
.hover\:bg-teal-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(4 47 46 / var(--tw-bg-opacity, 1));
}
.hover\:bg-tremor-background-muted:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.hover\:bg-tremor-background-subtle:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.hover\:bg-tremor-brand-emphasis:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(9 88 217 / var(--tw-bg-opacity, 1));
}
.hover\:bg-tremor-brand-faint:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(186 224 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity, 1));
}
.hover\:bg-violet-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(46 16 101 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity, 1));
}
.hover\:bg-yellow-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(66 32 6 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity, 1));
}
.hover\:bg-zinc-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity, 1));
}
.hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
}
.hover\:\!text-blue-800:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1)) !important;
}
.hover\:text-amber-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-200:hover {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-300:hover {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-500:hover {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-600:hover {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-700:hover {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-800:hover {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-900:hover {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
.hover\:text-amber-950:hover {
    --tw-text-opacity: 1;
    color: rgb(69 26 3 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
.hover\:text-blue-950:hover {
    --tw-text-opacity: 1;
    color: rgb(23 37 84 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-100:hover {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-200:hover {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-300:hover {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-400:hover {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-50:hover {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-500:hover {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-600:hover {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-700:hover {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-800:hover {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-900:hover {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity, 1));
}
.hover\:text-cyan-950:hover {
    --tw-text-opacity: 1;
    color: rgb(8 51 68 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-100:hover {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-200:hover {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-300:hover {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-400:hover {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-50:hover {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-500:hover {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-600:hover {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-700:hover {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-800:hover {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-900:hover {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity, 1));
}
.hover\:text-emerald-950:hover {
    --tw-text-opacity: 1;
    color: rgb(2 44 34 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-100:hover {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-200:hover {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-300:hover {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-400:hover {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-50:hover {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-500:hover {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-600:hover {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-700:hover {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-800:hover {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-900:hover {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity, 1));
}
.hover\:text-fuchsia-950:hover {
    --tw-text-opacity: 1;
    color: rgb(74 4 78 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.hover\:text-gray-950:hover {
    --tw-text-opacity: 1;
    color: rgb(3 7 18 / var(--tw-text-opacity, 1));
}
.hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity, 1));
}
.hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity, 1));
}
.hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}
.hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}
.hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity, 1));
}
.hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
.hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}
.hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
.hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}
.hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.hover\:text-green-950:hover {
    --tw-text-opacity: 1;
    color: rgb(5 46 22 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity, 1));
}
.hover\:text-indigo-950:hover {
    --tw-text-opacity: 1;
    color: rgb(30 27 75 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-100:hover {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-200:hover {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-300:hover {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-400:hover {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-50:hover {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-500:hover {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-600:hover {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-700:hover {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-800:hover {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-900:hover {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}
.hover\:text-lime-950:hover {
    --tw-text-opacity: 1;
    color: rgb(26 46 5 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-100:hover {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-200:hover {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-300:hover {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-400:hover {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-500:hover {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-600:hover {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-700:hover {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-800:hover {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-900:hover {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity, 1));
}
.hover\:text-neutral-950:hover {
    --tw-text-opacity: 1;
    color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-100:hover {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-500:hover {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-600:hover {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-700:hover {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-800:hover {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-900:hover {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}
.hover\:text-orange-950:hover {
    --tw-text-opacity: 1;
    color: rgb(67 20 7 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgb(157 23 77 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity, 1));
}
.hover\:text-pink-950:hover {
    --tw-text-opacity: 1;
    color: rgb(80 7 36 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
.hover\:text-purple-950:hover {
    --tw-text-opacity: 1;
    color: rgb(59 7 100 / var(--tw-text-opacity, 1));
}
.hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity, 1));
}
.hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity, 1));
}
.hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}
.hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}
.hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity, 1));
}
.hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}
.hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}
.hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}
.hover\:text-red-950:hover {
    --tw-text-opacity: 1;
    color: rgb(69 10 10 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-100:hover {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-400:hover {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-50:hover {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-500:hover {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-600:hover {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-700:hover {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-800:hover {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-900:hover {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity, 1));
}
.hover\:text-rose-950:hover {
    --tw-text-opacity: 1;
    color: rgb(76 5 25 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-100:hover {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-200:hover {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-300:hover {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-400:hover {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-500:hover {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-600:hover {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-700:hover {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-800:hover {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-900:hover {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}
.hover\:text-sky-950:hover {
    --tw-text-opacity: 1;
    color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-100:hover {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-200:hover {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-300:hover {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-400:hover {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-50:hover {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-500:hover {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-600:hover {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-700:hover {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-800:hover {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-900:hover {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}
.hover\:text-slate-950:hover {
    --tw-text-opacity: 1;
    color: rgb(2 6 23 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-100:hover {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-200:hover {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-300:hover {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-400:hover {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-500:hover {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-600:hover {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-700:hover {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-800:hover {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-900:hover {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}
.hover\:text-stone-950:hover {
    --tw-text-opacity: 1;
    color: rgb(12 10 9 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-100:hover {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-200:hover {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-300:hover {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-400:hover {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-50:hover {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-500:hover {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-600:hover {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-700:hover {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-800:hover {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-900:hover {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity, 1));
}
.hover\:text-teal-950:hover {
    --tw-text-opacity: 1;
    color: rgb(4 47 46 / var(--tw-text-opacity, 1));
}
.hover\:text-tremor-brand-emphasis:hover {
    --tw-text-opacity: 1;
    color: rgb(9 88 217 / var(--tw-text-opacity, 1));
}
.hover\:text-tremor-content:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.hover\:text-tremor-content-emphasis:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-100:hover {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-200:hover {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-300:hover {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-400:hover {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-50:hover {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-500:hover {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-600:hover {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-700:hover {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-800:hover {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-900:hover {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity, 1));
}
.hover\:text-violet-950:hover {
    --tw-text-opacity: 1;
    color: rgb(46 16 101 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity, 1));
}
.hover\:text-yellow-950:hover {
    --tw-text-opacity: 1;
    color: rgb(66 32 6 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-100:hover {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-200:hover {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-300:hover {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-400:hover {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-50:hover {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-500:hover {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-600:hover {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-700:hover {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-800:hover {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-900:hover {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1));
}
.hover\:text-zinc-950:hover {
    --tw-text-opacity: 1;
    color: rgb(9 9 11 / var(--tw-text-opacity, 1));
}
.hover\:underline:hover {
    text-decoration-line: underline;
}
.focus\:border-\[\#BDC7D1\]:focus {
    --tw-border-opacity: 1;
    border-color: rgb(189 199 209 / var(--tw-border-opacity, 1));
}
.focus\:border-\[394857\]:focus {
    border-color: 394857;
}
.focus\:border-\[transparent\]:focus {
    border-color: transparent;
}
.focus\:border-tremor-brand-subtle:focus {
    --tw-border-opacity: 1;
    border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
}
.focus\:bg-\[\#f5f7fa\]:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(245 247 250 / var(--tw-bg-opacity, 1));
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-tremor-brand-muted:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(105 177 255 / var(--tw-ring-opacity, 1));
}
.disabled\:hover\:bg-transparent:hover:disabled {
    background-color: transparent;
}
.group\/item:hover .group-hover\/item\:visible {
    visibility: visible;
}
.group:hover .group-hover\:bg-tremor-brand-subtle\/30 {
    background-color: rgb(64 150 255 / 0.3);
}
.group:hover .group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
}
.group:hover .group-hover\:text-tremor-content-emphasis {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.group:active .group-active\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.aria-selected\:\!bg-tremor-background-subtle[aria-selected="true"] {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1)) !important;
}
.aria-selected\:bg-tremor-background-emphasis[aria-selected="true"] {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.aria-selected\:\!text-tremor-content[aria-selected="true"] {
    --tw-text-opacity: 1 !important;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1)) !important;
}
.aria-selected\:text-dark-tremor-brand-inverted[aria-selected="true"] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.aria-selected\:text-tremor-brand-inverted[aria-selected="true"] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.aria-selected\:text-tremor-content-inverted[aria-selected="true"] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.data-\[selected\]\:border-b-2[data-selected] {
    border-bottom-width: 2px;
}
.data-\[selected\]\:border-tremor-border[data-selected] {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.data-\[selected\]\:border-tremor-brand[data-selected] {
    --tw-border-opacity: 1;
    border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
}
.data-\[focus\]\:bg-tremor-background-muted[data-focus] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.data-\[selected\]\:bg-tremor-background[data-selected] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.data-\[selected\]\:bg-tremor-background-muted[data-selected] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.data-\[focus\]\:text-tremor-content-strong[data-focus] {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.data-\[selected\]\:text-tremor-brand[data-selected] {
    --tw-text-opacity: 1;
    color: rgb(22 119 255 / var(--tw-text-opacity, 1));
}
.data-\[selected\]\:text-tremor-content-strong[data-selected] {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.data-\[closed\]\:opacity-0[data-closed] {
    opacity: 0;
}
.data-\[selected\]\:shadow-tremor-input[data-selected] {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.data-\[enter\]\:duration-300[data-enter] {
    transition-duration: 300ms;
}
.data-\[leave\]\:duration-200[data-leave] {
    transition-duration: 200ms;
}
.data-\[enter\]\:ease-out[data-enter] {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.data-\[leave\]\:ease-in[data-leave] {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ui-selected\:border-amber-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-amber-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(69 26 3 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-blue-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(23 37 84 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-cyan-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(8 51 68 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-emerald-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(2 44 34 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-fuchsia-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(74 4 78 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-gray-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(3 7 18 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-green-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(5 46 22 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-indigo-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(30 27 75 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-lime-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(26 46 5 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-neutral-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(10 10 10 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-orange-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(67 20 7 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(157 23 77 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-pink-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(80 7 36 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-purple-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(59 7 100 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-red-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(69 10 10 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-rose-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(76 5 25 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-sky-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-slate-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(2 6 23 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-stone-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(12 10 9 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-teal-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(4 47 46 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-violet-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(46 16 101 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-yellow-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(66 32 6 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-100[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-200[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-300[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-400[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-50[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-500[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-600[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-700[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-800[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-900[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity, 1));
}
.ui-selected\:border-zinc-950[data-headlessui-state~="selected"] {
    --tw-border-opacity: 1;
    border-color: rgb(9 9 11 / var(--tw-border-opacity, 1));
}
.ui-selected\:bg-amber-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-amber-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(69 26 3 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-blue-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(23 37 84 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-cyan-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(8 51 68 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-emerald-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(2 44 34 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-fuchsia-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(74 4 78 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-gray-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-green-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(5 46 22 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-indigo-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 27 75 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-lime-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(26 46 5 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-neutral-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(10 10 10 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-orange-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(67 20 7 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(157 23 77 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-pink-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(80 7 36 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-purple-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 7 100 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-red-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(69 10 10 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-rose-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(76 5 25 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-sky-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-slate-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-stone-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(12 10 9 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-teal-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(4 47 46 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-violet-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(46 16 101 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-yellow-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(66 32 6 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-100[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-200[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-300[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-400[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-50[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-500[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-600[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-700[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-800[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-900[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity, 1));
}
.ui-selected\:bg-zinc-950[data-headlessui-state~="selected"] {
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity, 1));
}
.ui-selected\:text-amber-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-amber-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(69 26 3 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-blue-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(23 37 84 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-cyan-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(8 51 68 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-emerald-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(2 44 34 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-fuchsia-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(74 4 78 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-gray-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(3 7 18 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-green-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(5 46 22 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-indigo-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(30 27 75 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-lime-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(26 46 5 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-neutral-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-orange-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(67 20 7 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(157 23 77 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-pink-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(80 7 36 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-purple-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(59 7 100 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-red-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(69 10 10 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-rose-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(76 5 25 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-sky-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-slate-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(2 6 23 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-stone-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(12 10 9 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-teal-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(4 47 46 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-violet-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(46 16 101 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-yellow-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(66 32 6 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-100[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-200[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-300[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-400[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-50[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-500[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-600[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-700[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-800[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-900[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1));
}
.ui-selected\:text-zinc-950[data-headlessui-state~="selected"] {
    --tw-text-opacity: 1;
    color: rgb(9 9 11 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 243 199 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-200 {
    --tw-border-opacity: 1;
    border-color: rgb(253 230 138 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 211 77 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 251 235 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-500 {
    --tw-border-opacity: 1;
    border-color: rgb(245 158 11 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-600 {
    --tw-border-opacity: 1;
    border-color: rgb(217 119 6 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-800 {
    --tw-border-opacity: 1;
    border-color: rgb(146 64 14 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-900 {
    --tw-border-opacity: 1;
    border-color: rgb(120 53 15 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-950 {
    --tw-border-opacity: 1;
    border-color: rgb(69 26 3 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgb(219 234 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgb(239 246 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgb(37 99 235 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 64 175 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-950 {
    --tw-border-opacity: 1;
    border-color: rgb(23 37 84 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-100 {
    --tw-border-opacity: 1;
    border-color: rgb(207 250 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-200 {
    --tw-border-opacity: 1;
    border-color: rgb(165 243 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-300 {
    --tw-border-opacity: 1;
    border-color: rgb(103 232 249 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-400 {
    --tw-border-opacity: 1;
    border-color: rgb(34 211 238 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 254 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-500 {
    --tw-border-opacity: 1;
    border-color: rgb(6 182 212 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-600 {
    --tw-border-opacity: 1;
    border-color: rgb(8 145 178 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-700 {
    --tw-border-opacity: 1;
    border-color: rgb(14 116 144 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-800 {
    --tw-border-opacity: 1;
    border-color: rgb(21 94 117 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-900 {
    --tw-border-opacity: 1;
    border-color: rgb(22 78 99 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-950 {
    --tw-border-opacity: 1;
    border-color: rgb(8 51 68 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-100 {
    --tw-border-opacity: 1;
    border-color: rgb(209 250 229 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-200 {
    --tw-border-opacity: 1;
    border-color: rgb(167 243 208 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-300 {
    --tw-border-opacity: 1;
    border-color: rgb(110 231 183 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-400 {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-50 {
    --tw-border-opacity: 1;
    border-color: rgb(236 253 245 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-500 {
    --tw-border-opacity: 1;
    border-color: rgb(16 185 129 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-600 {
    --tw-border-opacity: 1;
    border-color: rgb(5 150 105 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-700 {
    --tw-border-opacity: 1;
    border-color: rgb(4 120 87 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-800 {
    --tw-border-opacity: 1;
    border-color: rgb(6 95 70 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-900 {
    --tw-border-opacity: 1;
    border-color: rgb(6 78 59 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-950 {
    --tw-border-opacity: 1;
    border-color: rgb(2 44 34 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-100 {
    --tw-border-opacity: 1;
    border-color: rgb(250 232 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-200 {
    --tw-border-opacity: 1;
    border-color: rgb(245 208 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-300 {
    --tw-border-opacity: 1;
    border-color: rgb(240 171 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-400 {
    --tw-border-opacity: 1;
    border-color: rgb(232 121 249 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 244 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-500 {
    --tw-border-opacity: 1;
    border-color: rgb(217 70 239 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-600 {
    --tw-border-opacity: 1;
    border-color: rgb(192 38 211 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-700 {
    --tw-border-opacity: 1;
    border-color: rgb(162 28 175 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-800 {
    --tw-border-opacity: 1;
    border-color: rgb(134 25 143 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-900 {
    --tw-border-opacity: 1;
    border-color: rgb(112 26 117 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-950 {
    --tw-border-opacity: 1;
    border-color: rgb(74 4 78 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-950 {
    --tw-border-opacity: 1;
    border-color: rgb(3 7 18 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgb(220 252 231 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 244 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-950 {
    --tw-border-opacity: 1;
    border-color: rgb(5 46 22 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 231 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgb(199 210 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgb(165 180 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgb(129 140 248 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgb(238 242 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgb(67 56 202 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgb(55 48 163 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgb(49 46 129 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-950 {
    --tw-border-opacity: 1;
    border-color: rgb(30 27 75 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-100 {
    --tw-border-opacity: 1;
    border-color: rgb(236 252 203 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-200 {
    --tw-border-opacity: 1;
    border-color: rgb(217 249 157 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-300 {
    --tw-border-opacity: 1;
    border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 230 53 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-50 {
    --tw-border-opacity: 1;
    border-color: rgb(247 254 231 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-500 {
    --tw-border-opacity: 1;
    border-color: rgb(132 204 22 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-600 {
    --tw-border-opacity: 1;
    border-color: rgb(101 163 13 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-700 {
    --tw-border-opacity: 1;
    border-color: rgb(77 124 15 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-800 {
    --tw-border-opacity: 1;
    border-color: rgb(63 98 18 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-900 {
    --tw-border-opacity: 1;
    border-color: rgb(54 83 20 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-950 {
    --tw-border-opacity: 1;
    border-color: rgb(26 46 5 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-500 {
    --tw-border-opacity: 1;
    border-color: rgb(115 115 115 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 82 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-800 {
    --tw-border-opacity: 1;
    border-color: rgb(38 38 38 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-900 {
    --tw-border-opacity: 1;
    border-color: rgb(23 23 23 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-950 {
    --tw-border-opacity: 1;
    border-color: rgb(10 10 10 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 237 213 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 215 170 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 186 116 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 247 237 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-500 {
    --tw-border-opacity: 1;
    border-color: rgb(249 115 22 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-600 {
    --tw-border-opacity: 1;
    border-color: rgb(234 88 12 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-700 {
    --tw-border-opacity: 1;
    border-color: rgb(194 65 12 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-800 {
    --tw-border-opacity: 1;
    border-color: rgb(154 52 18 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-900 {
    --tw-border-opacity: 1;
    border-color: rgb(124 45 18 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-950 {
    --tw-border-opacity: 1;
    border-color: rgb(67 20 7 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgb(252 231 243 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgb(251 207 232 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgb(249 168 212 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgb(244 114 182 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgb(253 242 248 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgb(236 72 153 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgb(219 39 119 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 24 93 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgb(157 23 77 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgb(131 24 67 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-950 {
    --tw-border-opacity: 1;
    border-color: rgb(80 7 36 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 245 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgb(107 33 168 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgb(88 28 135 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-950 {
    --tw-border-opacity: 1;
    border-color: rgb(59 7 100 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 226 226 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgb(252 165 165 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgb(248 113 113 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 242 242 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgb(220 38 38 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgb(185 28 28 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgb(127 29 29 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-950 {
    --tw-border-opacity: 1;
    border-color: rgb(69 10 10 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-100 {
    --tw-border-opacity: 1;
    border-color: rgb(255 228 230 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 205 211 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 164 175 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 113 133 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-50 {
    --tw-border-opacity: 1;
    border-color: rgb(255 241 242 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-500 {
    --tw-border-opacity: 1;
    border-color: rgb(244 63 94 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-600 {
    --tw-border-opacity: 1;
    border-color: rgb(225 29 72 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-700 {
    --tw-border-opacity: 1;
    border-color: rgb(190 18 60 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-800 {
    --tw-border-opacity: 1;
    border-color: rgb(159 18 57 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-900 {
    --tw-border-opacity: 1;
    border-color: rgb(136 19 55 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-950 {
    --tw-border-opacity: 1;
    border-color: rgb(76 5 25 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-100 {
    --tw-border-opacity: 1;
    border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-200 {
    --tw-border-opacity: 1;
    border-color: rgb(186 230 253 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-300 {
    --tw-border-opacity: 1;
    border-color: rgb(125 211 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-400 {
    --tw-border-opacity: 1;
    border-color: rgb(56 189 248 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 249 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-500 {
    --tw-border-opacity: 1;
    border-color: rgb(14 165 233 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-600 {
    --tw-border-opacity: 1;
    border-color: rgb(2 132 199 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-700 {
    --tw-border-opacity: 1;
    border-color: rgb(3 105 161 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-800 {
    --tw-border-opacity: 1;
    border-color: rgb(7 89 133 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-900 {
    --tw-border-opacity: 1;
    border-color: rgb(12 74 110 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-950 {
    --tw-border-opacity: 1;
    border-color: rgb(8 47 73 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-400 {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-50 {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-600 {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-700 {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-800 {
    --tw-border-opacity: 1;
    border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-900 {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-950 {
    --tw-border-opacity: 1;
    border-color: rgb(2 6 23 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 244 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-200 {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 228 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-300 {
    --tw-border-opacity: 1;
    border-color: rgb(214 211 209 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-400 {
    --tw-border-opacity: 1;
    border-color: rgb(168 162 158 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 249 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-500 {
    --tw-border-opacity: 1;
    border-color: rgb(120 113 108 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-600 {
    --tw-border-opacity: 1;
    border-color: rgb(87 83 78 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-700 {
    --tw-border-opacity: 1;
    border-color: rgb(68 64 60 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-800 {
    --tw-border-opacity: 1;
    border-color: rgb(41 37 36 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-900 {
    --tw-border-opacity: 1;
    border-color: rgb(28 25 23 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-950 {
    --tw-border-opacity: 1;
    border-color: rgb(12 10 9 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-100 {
    --tw-border-opacity: 1;
    border-color: rgb(204 251 241 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-200 {
    --tw-border-opacity: 1;
    border-color: rgb(153 246 228 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-400 {
    --tw-border-opacity: 1;
    border-color: rgb(45 212 191 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-50 {
    --tw-border-opacity: 1;
    border-color: rgb(240 253 250 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-500 {
    --tw-border-opacity: 1;
    border-color: rgb(20 184 166 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-600 {
    --tw-border-opacity: 1;
    border-color: rgb(13 148 136 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-700 {
    --tw-border-opacity: 1;
    border-color: rgb(15 118 110 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-800 {
    --tw-border-opacity: 1;
    border-color: rgb(17 94 89 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-900 {
    --tw-border-opacity: 1;
    border-color: rgb(19 78 74 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-950 {
    --tw-border-opacity: 1;
    border-color: rgb(4 47 46 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-100 {
    --tw-border-opacity: 1;
    border-color: rgb(237 233 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-200 {
    --tw-border-opacity: 1;
    border-color: rgb(221 214 254 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-300 {
    --tw-border-opacity: 1;
    border-color: rgb(196 181 253 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-400 {
    --tw-border-opacity: 1;
    border-color: rgb(167 139 250 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-50 {
    --tw-border-opacity: 1;
    border-color: rgb(245 243 255 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-500 {
    --tw-border-opacity: 1;
    border-color: rgb(139 92 246 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-600 {
    --tw-border-opacity: 1;
    border-color: rgb(124 58 237 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-700 {
    --tw-border-opacity: 1;
    border-color: rgb(109 40 217 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-800 {
    --tw-border-opacity: 1;
    border-color: rgb(91 33 182 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-900 {
    --tw-border-opacity: 1;
    border-color: rgb(76 29 149 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-950 {
    --tw-border-opacity: 1;
    border-color: rgb(46 16 101 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgb(254 249 195 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 240 138 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgb(253 224 71 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgb(254 252 232 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgb(234 179 8 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgb(202 138 4 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgb(161 98 7 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgb(133 77 14 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgb(113 63 18 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-950 {
    --tw-border-opacity: 1;
    border-color: rgb(66 32 6 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-100 {
    --tw-border-opacity: 1;
    border-color: rgb(244 244 245 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-200 {
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-400 {
    --tw-border-opacity: 1;
    border-color: rgb(161 161 170 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-50 {
    --tw-border-opacity: 1;
    border-color: rgb(250 250 250 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-500 {
    --tw-border-opacity: 1;
    border-color: rgb(113 113 122 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-600 {
    --tw-border-opacity: 1;
    border-color: rgb(82 82 91 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-700 {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-800 {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 42 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-900 {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 27 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-950 {
    --tw-border-opacity: 1;
    border-color: rgb(9 9 11 / var(--tw-border-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 230 138 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 211 77 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 119 6 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 53 15 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(69 26 3 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 37 84 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(103 232 249 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 254 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 145 178 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 116 144 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 51 68 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 44 34 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 4 78 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(5 46 22 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 27 75 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 230 53 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(247 254 231 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(101 163 13 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(77 124 15 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 46 5 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(163 163 163 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(10 10 10 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 88 12 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(194 65 12 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(154 52 18 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 45 18 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(67 20 7 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(157 23 77 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(80 7 36 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 7 100 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 113 113 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(69 10 10 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(76 5 25 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(8 47 73 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(214 211 209 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 162 158 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 249 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(87 83 78 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(68 64 60 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(12 10 9 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 47 46 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(46 16 101 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(202 138 4 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(133 77 14 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 63 18 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(66 32 6 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-100 {
    --tw-text-opacity: 1;
    color: rgb(254 243 199 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-200 {
    --tw-text-opacity: 1;
    color: rgb(253 230 138 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-300 {
    --tw-text-opacity: 1;
    color: rgb(252 211 77 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-400 {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-50 {
    --tw-text-opacity: 1;
    color: rgb(255 251 235 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-500 {
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-600 {
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-700 {
    --tw-text-opacity: 1;
    color: rgb(180 83 9 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-800 {
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-900 {
    --tw-text-opacity: 1;
    color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-950 {
    --tw-text-opacity: 1;
    color: rgb(69 26 3 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgb(239 246 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-950 {
    --tw-text-opacity: 1;
    color: rgb(23 37 84 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-100 {
    --tw-text-opacity: 1;
    color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-200 {
    --tw-text-opacity: 1;
    color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-300 {
    --tw-text-opacity: 1;
    color: rgb(103 232 249 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-400 {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-50 {
    --tw-text-opacity: 1;
    color: rgb(236 254 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-600 {
    --tw-text-opacity: 1;
    color: rgb(8 145 178 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-700 {
    --tw-text-opacity: 1;
    color: rgb(14 116 144 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-800 {
    --tw-text-opacity: 1;
    color: rgb(21 94 117 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-900 {
    --tw-text-opacity: 1;
    color: rgb(22 78 99 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-950 {
    --tw-text-opacity: 1;
    color: rgb(8 51 68 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-100 {
    --tw-text-opacity: 1;
    color: rgb(209 250 229 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-200 {
    --tw-text-opacity: 1;
    color: rgb(167 243 208 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-300 {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-50 {
    --tw-text-opacity: 1;
    color: rgb(236 253 245 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-700 {
    --tw-text-opacity: 1;
    color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-800 {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-900 {
    --tw-text-opacity: 1;
    color: rgb(6 78 59 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-950 {
    --tw-text-opacity: 1;
    color: rgb(2 44 34 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-100 {
    --tw-text-opacity: 1;
    color: rgb(250 232 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-200 {
    --tw-text-opacity: 1;
    color: rgb(245 208 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-300 {
    --tw-text-opacity: 1;
    color: rgb(240 171 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-400 {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-50 {
    --tw-text-opacity: 1;
    color: rgb(253 244 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-500 {
    --tw-text-opacity: 1;
    color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-600 {
    --tw-text-opacity: 1;
    color: rgb(192 38 211 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-700 {
    --tw-text-opacity: 1;
    color: rgb(162 28 175 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-800 {
    --tw-text-opacity: 1;
    color: rgb(134 25 143 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-900 {
    --tw-text-opacity: 1;
    color: rgb(112 26 117 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-950 {
    --tw-text-opacity: 1;
    color: rgb(74 4 78 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-950 {
    --tw-text-opacity: 1;
    color: rgb(3 7 18 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgb(220 252 231 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-950 {
    --tw-text-opacity: 1;
    color: rgb(5 46 22 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgb(165 180 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgb(238 242 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgb(67 56 202 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgb(49 46 129 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-950 {
    --tw-text-opacity: 1;
    color: rgb(30 27 75 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-100 {
    --tw-text-opacity: 1;
    color: rgb(236 252 203 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-200 {
    --tw-text-opacity: 1;
    color: rgb(217 249 157 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-300 {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-400 {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-50 {
    --tw-text-opacity: 1;
    color: rgb(247 254 231 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-500 {
    --tw-text-opacity: 1;
    color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-600 {
    --tw-text-opacity: 1;
    color: rgb(101 163 13 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-700 {
    --tw-text-opacity: 1;
    color: rgb(77 124 15 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-800 {
    --tw-text-opacity: 1;
    color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-900 {
    --tw-text-opacity: 1;
    color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-950 {
    --tw-text-opacity: 1;
    color: rgb(26 46 5 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 245 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-200 {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 212 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-800 {
    --tw-text-opacity: 1;
    color: rgb(38 38 38 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-900 {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-950 {
    --tw-text-opacity: 1;
    color: rgb(10 10 10 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-100 {
    --tw-text-opacity: 1;
    color: rgb(255 237 213 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-200 {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-300 {
    --tw-text-opacity: 1;
    color: rgb(253 186 116 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-400 {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-50 {
    --tw-text-opacity: 1;
    color: rgb(255 247 237 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-800 {
    --tw-text-opacity: 1;
    color: rgb(154 52 18 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-900 {
    --tw-text-opacity: 1;
    color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-950 {
    --tw-text-opacity: 1;
    color: rgb(67 20 7 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgb(252 231 243 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgb(251 207 232 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgb(249 168 212 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgb(253 242 248 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgb(219 39 119 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgb(157 23 77 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgb(131 24 67 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-950 {
    --tw-text-opacity: 1;
    color: rgb(80 7 36 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgb(243 232 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgb(233 213 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-950 {
    --tw-text-opacity: 1;
    color: rgb(59 7 100 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgb(254 226 226 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgb(254 242 242 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-950 {
    --tw-text-opacity: 1;
    color: rgb(69 10 10 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-100 {
    --tw-text-opacity: 1;
    color: rgb(255 228 230 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-200 {
    --tw-text-opacity: 1;
    color: rgb(254 205 211 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-300 {
    --tw-text-opacity: 1;
    color: rgb(253 164 175 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-400 {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-50 {
    --tw-text-opacity: 1;
    color: rgb(255 241 242 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-500 {
    --tw-text-opacity: 1;
    color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-600 {
    --tw-text-opacity: 1;
    color: rgb(225 29 72 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-700 {
    --tw-text-opacity: 1;
    color: rgb(190 18 60 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-800 {
    --tw-text-opacity: 1;
    color: rgb(159 18 57 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-900 {
    --tw-text-opacity: 1;
    color: rgb(136 19 55 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-950 {
    --tw-text-opacity: 1;
    color: rgb(76 5 25 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-100 {
    --tw-text-opacity: 1;
    color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-200 {
    --tw-text-opacity: 1;
    color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-400 {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-50 {
    --tw-text-opacity: 1;
    color: rgb(240 249 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-500 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-600 {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-700 {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-800 {
    --tw-text-opacity: 1;
    color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-900 {
    --tw-text-opacity: 1;
    color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-950 {
    --tw-text-opacity: 1;
    color: rgb(8 47 73 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-100 {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-300 {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-50 {
    --tw-text-opacity: 1;
    color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-800 {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-950 {
    --tw-text-opacity: 1;
    color: rgb(2 6 23 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-100 {
    --tw-text-opacity: 1;
    color: rgb(245 245 244 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-200 {
    --tw-text-opacity: 1;
    color: rgb(231 229 228 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-300 {
    --tw-text-opacity: 1;
    color: rgb(214 211 209 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-400 {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 249 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-500 {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-600 {
    --tw-text-opacity: 1;
    color: rgb(87 83 78 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-700 {
    --tw-text-opacity: 1;
    color: rgb(68 64 60 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-800 {
    --tw-text-opacity: 1;
    color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-900 {
    --tw-text-opacity: 1;
    color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-950 {
    --tw-text-opacity: 1;
    color: rgb(12 10 9 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-100 {
    --tw-text-opacity: 1;
    color: rgb(204 251 241 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-200 {
    --tw-text-opacity: 1;
    color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-300 {
    --tw-text-opacity: 1;
    color: rgb(94 234 212 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-400 {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 250 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-500 {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-600 {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-700 {
    --tw-text-opacity: 1;
    color: rgb(15 118 110 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-800 {
    --tw-text-opacity: 1;
    color: rgb(17 94 89 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-900 {
    --tw-text-opacity: 1;
    color: rgb(19 78 74 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-950 {
    --tw-text-opacity: 1;
    color: rgb(4 47 46 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-100 {
    --tw-text-opacity: 1;
    color: rgb(237 233 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-200 {
    --tw-text-opacity: 1;
    color: rgb(221 214 254 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-300 {
    --tw-text-opacity: 1;
    color: rgb(196 181 253 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-400 {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-50 {
    --tw-text-opacity: 1;
    color: rgb(245 243 255 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-500 {
    --tw-text-opacity: 1;
    color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-600 {
    --tw-text-opacity: 1;
    color: rgb(124 58 237 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-700 {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-800 {
    --tw-text-opacity: 1;
    color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-900 {
    --tw-text-opacity: 1;
    color: rgb(76 29 149 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-950 {
    --tw-text-opacity: 1;
    color: rgb(46 16 101 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgb(254 249 195 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgb(254 240 138 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgb(254 252 232 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgb(113 63 18 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-950 {
    --tw-text-opacity: 1;
    color: rgb(66 32 6 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-100 {
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-200 {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-300 {
    --tw-text-opacity: 1;
    color: rgb(212 212 216 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-400 {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-50 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-500 {
    --tw-text-opacity: 1;
    color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-700 {
    --tw-text-opacity: 1;
    color: rgb(63 63 70 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-800 {
    --tw-text-opacity: 1;
    color: rgb(39 39 42 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-900 {
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity, 1));
}
:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-950 {
    --tw-text-opacity: 1;
    color: rgb(9 9 11 / var(--tw-text-opacity, 1));
}
@media (min-width: 640px) {
    .sm\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .sm\:col-span-10 {
        grid-column: span 10 / span 10;
    }
    .sm\:col-span-11 {
        grid-column: span 11 / span 11;
    }
    .sm\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .sm\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .sm\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .sm\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .sm\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .sm\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .sm\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .sm\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .sm\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .sm\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .sm\:grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .sm\:grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .sm\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .sm\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .sm\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .sm\:grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .sm\:grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .sm\:grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .sm\:grid-cols-none {
        grid-template-columns: none;
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
}
@media (min-width: 768px) {
    .md\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .md\:col-span-10 {
        grid-column: span 10 / span 10;
    }
    .md\:col-span-11 {
        grid-column: span 11 / span 11;
    }
    .md\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .md\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .md\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .md\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .md\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .md\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .md\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .md\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .md\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .md\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .md\:grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .md\:grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .md\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .md\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .md\:grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .md\:grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .md\:grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .md\:grid-cols-none {
        grid-template-columns: none;
    }
}
@media (min-width: 1024px) {
    .lg\:left-14 {
        left: 3.5rem;
    }
    .lg\:top-14 {
        top: 3.5rem;
    }
    .lg\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .lg\:col-span-10 {
        grid-column: span 10 / span 10;
    }
    .lg\:col-span-11 {
        grid-column: span 11 / span 11;
    }
    .lg\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .lg\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .lg\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .lg\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .lg\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .lg\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .lg\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .lg\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .lg\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .lg\:flex {
        display: flex;
    }
    .lg\:w-1\/2 {
        width: 50%;
    }
    .lg\:max-w-\[200px\] {
        max-width: 200px;
    }
    .lg\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .lg\:grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }
    .lg\:grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }
    .lg\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .lg\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .lg\:grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    .lg\:grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .lg\:grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    .lg\:grid-cols-none {
        grid-template-columns: none;
    }
    .lg\:justify-normal {
        justify-content: normal;
    }
    .lg\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (min-width: 1536px) {
    .\32xl\:h-auto {
        height: auto;
    }
    .\32xl\:w-\[55\%\] {
        width: 55%;
    }
    .\32xl\:flex-row {
        flex-direction: row;
    }
    .\32xl\:items-center {
        align-items: center;
    }
}
@media (prefers-color-scheme: dark) {
    .dark\:divide-dark-tremor-border > :not([hidden]) ~ :not([hidden]) {
        --tw-divide-opacity: 1;
        border-color: rgb(31 41 55 / var(--tw-divide-opacity, 1));
    }
    .dark\:border-dark-tremor-background {
        --tw-border-opacity: 1;
        border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
    }
    .dark\:border-dark-tremor-border {
        --tw-border-opacity: 1;
        border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
    }
    .dark\:border-dark-tremor-brand {
        --tw-border-opacity: 1;
        border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
    }
    .dark\:border-dark-tremor-brand-emphasis {
        --tw-border-opacity: 1;
        border-color: rgb(9 88 217 / var(--tw-border-opacity, 1));
    }
    .dark\:border-dark-tremor-brand-inverted {
        --tw-border-opacity: 1;
        border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
    }
    .dark\:border-dark-tremor-brand-subtle {
        --tw-border-opacity: 1;
        border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
    }
    .dark\:border-red-500 {
        --tw-border-opacity: 1;
        border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
    }
    .dark\:bg-dark-tremor-background {
        --tw-bg-opacity: 1;
        background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-background-emphasis {
        --tw-bg-opacity: 1;
        background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-background-muted {
        --tw-bg-opacity: 1;
        background-color: rgb(19 26 43 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-background-subtle {
        --tw-bg-opacity: 1;
        background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-border {
        --tw-bg-opacity: 1;
        background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-brand {
        --tw-bg-opacity: 1;
        background-color: rgb(22 119 255 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-brand-muted {
        --tw-bg-opacity: 1;
        background-color: rgb(64 150 255 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-dark-tremor-brand-muted\/50 {
        background-color: rgb(64 150 255 / 0.5);
    }
    .dark\:bg-dark-tremor-brand-muted\/70 {
        background-color: rgb(64 150 255 / 0.7);
    }
    .dark\:bg-dark-tremor-brand-subtle\/60 {
        background-color: rgb(64 150 255 / 0.6);
    }
    .dark\:bg-dark-tremor-content-subtle {
        --tw-bg-opacity: 1;
        background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-slate-950\/50 {
        background-color: rgb(2 6 23 / 0.5);
    }
    .dark\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    }
    .dark\:bg-opacity-10 {
        --tw-bg-opacity: 0.1;
    }
    .dark\:bg-opacity-5 {
        --tw-bg-opacity: 0.05;
    }
    .dark\:fill-dark-tremor-content {
        fill: #6b7280;
    }
    .dark\:fill-dark-tremor-content-emphasis {
        fill: #e5e7eb;
    }
    .dark\:stroke-dark-tremor-background {
        stroke: #111827;
    }
    .dark\:stroke-dark-tremor-border {
        stroke: #1f2937;
    }
    .dark\:stroke-dark-tremor-brand {
        stroke: #1677ff;
    }
    .dark\:stroke-dark-tremor-brand-muted {
        stroke: #4096ff;
    }
    .dark\:text-dark-tremor-brand {
        --tw-text-opacity: 1;
        color: rgb(22 119 255 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-brand-emphasis {
        --tw-text-opacity: 1;
        color: rgb(9 88 217 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-brand-inverted {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-content {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-content-emphasis {
        --tw-text-opacity: 1;
        color: rgb(229 231 235 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-content-strong {
        --tw-text-opacity: 1;
        color: rgb(249 250 251 / var(--tw-text-opacity, 1));
    }
    .dark\:text-dark-tremor-content-subtle {
        --tw-text-opacity: 1;
        color: rgb(75 85 99 / var(--tw-text-opacity, 1));
    }
    .dark\:text-red-500 {
        --tw-text-opacity: 1;
        color: rgb(239 68 68 / var(--tw-text-opacity, 1));
    }
    .dark\:text-tremor-content-emphasis {
        --tw-text-opacity: 1;
        color: rgb(55 65 81 / var(--tw-text-opacity, 1));
    }
    .dark\:accent-dark-tremor-brand {
        accent-color: #1677ff;
    }
    .dark\:opacity-25 {
        opacity: 0.25;
    }
    .dark\:shadow-dark-tremor-card {
        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .dark\:shadow-dark-tremor-dropdown {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .dark\:shadow-dark-tremor-input {
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .dark\:outline-dark-tremor-brand {
        outline-color: #1677ff;
    }
    .dark\:ring-dark-tremor-brand-inverted {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
    }
    .dark\:ring-dark-tremor-brand-muted {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(64 150 255 / var(--tw-ring-opacity, 1));
    }
    .dark\:ring-dark-tremor-ring {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity, 1));
    }
    .dark\:ring-opacity-60 {
        --tw-ring-opacity: 0.6;
    }
    .dark\:placeholder\:text-dark-tremor-content::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-dark-tremor-content::placeholder {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-dark-tremor-content-subtle::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(75 85 99 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-dark-tremor-content-subtle::placeholder {
        --tw-text-opacity: 1;
        color: rgb(75 85 99 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-red-500::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(239 68 68 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-red-500::placeholder {
        --tw-text-opacity: 1;
        color: rgb(239 68 68 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-tremor-content::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-tremor-content::placeholder {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-tremor-content-subtle::-moz-placeholder {
        --tw-text-opacity: 1;
        color: rgb(156 163 175 / var(--tw-text-opacity, 1));
    }
    .dark\:placeholder\:text-tremor-content-subtle::placeholder {
        --tw-text-opacity: 1;
        color: rgb(156 163 175 / var(--tw-text-opacity, 1));
    }
    .dark\:hover\:border-dark-tremor-brand-emphasis:hover {
        --tw-border-opacity: 1;
        border-color: rgb(9 88 217 / var(--tw-border-opacity, 1));
    }
    .dark\:hover\:bg-dark-tremor-background-muted:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(19 26 43 / var(--tw-bg-opacity, 1));
    }
    .dark\:hover\:bg-dark-tremor-background-subtle:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
    }
    .dark\:hover\:bg-dark-tremor-background-subtle\/40:hover {
        background-color: rgb(31 41 55 / 0.4);
    }
    .dark\:hover\:bg-dark-tremor-brand-emphasis:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(9 88 217 / var(--tw-bg-opacity, 1));
    }
    .dark\:hover\:bg-dark-tremor-brand-faint:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(186 224 255 / var(--tw-bg-opacity, 1));
    }
    .dark\:hover\:bg-opacity-20:hover {
        --tw-bg-opacity: 0.2;
    }
    .dark\:hover\:text-dark-tremor-brand-emphasis:hover {
        --tw-text-opacity: 1;
        color: rgb(9 88 217 / var(--tw-text-opacity, 1));
    }
    .dark\:hover\:text-dark-tremor-content:hover {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:hover\:text-tremor-content:hover {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:hover\:text-tremor-content-emphasis:hover {
        --tw-text-opacity: 1;
        color: rgb(55 65 81 / var(--tw-text-opacity, 1));
    }
    .hover\:dark\:text-dark-tremor-content:hover {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:focus\:border-dark-tremor-brand-subtle:focus {
        --tw-border-opacity: 1;
        border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
    }
    .focus\:dark\:border-dark-tremor-brand-subtle:focus {
        --tw-border-opacity: 1;
        border-color: rgb(64 150 255 / var(--tw-border-opacity, 1));
    }
    .dark\:focus\:ring-dark-tremor-brand-muted:focus {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(64 150 255 / var(--tw-ring-opacity, 1));
    }
    .focus\:dark\:ring-dark-tremor-brand-muted:focus {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(64 150 255 / var(--tw-ring-opacity, 1));
    }
    .group:hover .group-hover\:dark\:bg-dark-tremor-brand-subtle\/70 {
        background-color: rgb(64 150 255 / 0.7);
    }
    .group:hover .dark\:group-hover\:text-dark-tremor-content-emphasis {
        --tw-text-opacity: 1;
        color: rgb(229 231 235 / var(--tw-text-opacity, 1));
    }
    .aria-selected\:dark\:\!bg-dark-tremor-background-subtle[aria-selected="true"] {
        --tw-bg-opacity: 1 !important;
        background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1)) !important;
    }
    .dark\:aria-selected\:bg-dark-tremor-background-emphasis[aria-selected="true"] {
        --tw-bg-opacity: 1;
        background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
    }
    .dark\:aria-selected\:text-dark-tremor-brand-inverted[aria-selected="true"] {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity, 1));
    }
    .dark\:aria-selected\:text-dark-tremor-content-inverted[aria-selected="true"] {
        --tw-text-opacity: 1;
        color: rgb(3 7 18 / var(--tw-text-opacity, 1));
    }
    .dark\:data-\[selected\]\:border-dark-tremor-border[data-selected] {
        --tw-border-opacity: 1;
        border-color: rgb(31 41 55 / var(--tw-border-opacity, 1));
    }
    .data-\[selected\]\:dark\:border-dark-tremor-brand[data-selected] {
        --tw-border-opacity: 1;
        border-color: rgb(22 119 255 / var(--tw-border-opacity, 1));
    }
    .dark\:data-\[focus\]\:bg-dark-tremor-background-muted[data-focus] {
        --tw-bg-opacity: 1;
        background-color: rgb(19 26 43 / var(--tw-bg-opacity, 1));
    }
    .dark\:data-\[selected\]\:bg-dark-tremor-background[data-selected] {
        --tw-bg-opacity: 1;
        background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
    }
    .dark\:data-\[selected\]\:bg-dark-tremor-background-muted[data-selected] {
        --tw-bg-opacity: 1;
        background-color: rgb(19 26 43 / var(--tw-bg-opacity, 1));
    }
    .dark\:data-\[focus\]\:text-dark-tremor-content-strong[data-focus] {
        --tw-text-opacity: 1;
        color: rgb(249 250 251 / var(--tw-text-opacity, 1));
    }
    .dark\:data-\[selected\]\:text-dark-tremor-brand[data-selected] {
        --tw-text-opacity: 1;
        color: rgb(22 119 255 / var(--tw-text-opacity, 1));
    }
    .dark\:data-\[selected\]\:text-dark-tremor-content-strong[data-selected] {
        --tw-text-opacity: 1;
        color: rgb(249 250 251 / var(--tw-text-opacity, 1));
    }
    .data-\[selected\]\:dark\:text-dark-tremor-brand[data-selected] {
        --tw-text-opacity: 1;
        color: rgb(22 119 255 / var(--tw-text-opacity, 1));
    }
    .dark\:data-\[selected\]\:shadow-dark-tremor-input[data-selected] {
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
}
.\[\&\&\]\:\!mb-0.\[\&\&\]\:\!mb-0 {
    margin-bottom: 0px !important;
}
.\[\&\.ant-collapse-header\]\:\!px-2\.5.ant-collapse-header {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
}
.\[\&\.ant-layout-content\]\:m-0.ant-layout-content {
    margin: 0px;
}
.\[\&\.ant-layout-content\]\:p-0.ant-layout-content {
    padding: 0px;
}
.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
}
.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
}
.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
    display: none;
}
.\[\&\:\:-webkit-scrollbar\]\:w-0::-webkit-scrollbar {
    width: 0px;
}
.\[\&\:hover_input\]\:\!text-left:hover input {
    text-align: left !important;
}
.\[\&\:not\(\[data-selected\]\)\]\:text-tremor-content:not([data-selected]) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.\[\&\:not\(\[data-selected\]\)\]\:hover\:text-tremor-content-emphasis:hover:not([data-selected]) {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
@media (prefers-color-scheme: dark) {
    .\[\&\:not\(\[data-selected\]\)\]\:dark\:text-dark-tremor-content:not([data-selected]) {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .dark\:\[\&\:not\(\[data-selected\]\)\]\:text-dark-tremor-content:not([data-selected]) {
        --tw-text-opacity: 1;
        color: rgb(107 114 128 / var(--tw-text-opacity, 1));
    }
    .\[\&\:not\(\[data-selected\]\)\]\:dark\:hover\:border-dark-tremor-content-emphasis:hover:not([data-selected]) {
        --tw-border-opacity: 1;
        border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
    }
    .\[\&\:not\(\[data-selected\]\)\]\:dark\:hover\:text-dark-tremor-content-emphasis:hover:not([data-selected]) {
        --tw-text-opacity: 1;
        color: rgb(229 231 235 / var(--tw-text-opacity, 1));
    }
    .dark\:\[\&\:not\(\[data-selected\]\)\]\:hover\:text-dark-tremor-content-emphasis:hover:not([data-selected]) {
        --tw-text-opacity: 1;
        color: rgb(229 231 235 / var(--tw-text-opacity, 1));
    }
}
.\[\&\]\:\!min-h-4 {
    min-height: 1rem !important;
}
.\[\&_\.agenta-rich-text-editor\]\:w-full .agenta-rich-text-editor {
    width: 100%;
}
.\[\&_\.agenta-rich-text-editor_\*\]\:\!text-\[red\] .agenta-rich-text-editor * {
    --tw-text-opacity: 1 !important;
    color: rgb(255 0 0 / var(--tw-text-opacity, 1)) !important;
}
.\[\&_\.ant-collapse-content-box\]\:\!px-4 .ant-collapse-content-box {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.\[\&_\.ant-collapse-expand-icon\]\:\!self-center .ant-collapse-expand-icon {
    align-self: center !important;
}
.\[\&_\.ant-collapse-header\]\:\!sticky .ant-collapse-header {
    position: sticky !important;
}
.\[\&_\.ant-collapse-header\]\:\!top-\[48px\] .ant-collapse-header {
    top: 48px !important;
}
.\[\&_\.ant-collapse-header\]\:\!z-\[2\] .ant-collapse-header {
    z-index: 2 !important;
}
.\[\&_\.ant-collapse-header\]\:\!bg-white .ant-collapse-header {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}
.\[\&_\.ant-collapse-header\]\:\!pl-3 .ant-collapse-header {
    padding-left: 0.75rem !important;
}
.\[\&_\.ant-collapse-header\]\:\!pr-4 .ant-collapse-header {
    padding-right: 1rem !important;
}
.\[\&_\.ant-collapse\]\:\!bg-\[transparent\] .ant-collapse {
    background-color: transparent !important;
}
.\[\&_\.ant-form-item-required\]\:font-medium .ant-form-item-required {
    font-weight: 500;
}
.\[\&_\.ant-form-item-required\]\:before\:\!hidden .ant-form-item-required::before {
    content: var(--tw-content);
    display: none !important;
}
.\[\&_\.ant-form-item\]\:mb-0 .ant-form-item {
    margin-bottom: 0px;
}
.\[\&_\.ant-form-item\]\:mb-3 .ant-form-item {
    margin-bottom: 0.75rem;
}
.\[\&_\.ant-menu-item\]\:flex .ant-menu-item {
    display: flex;
}
.\[\&_\.ant-menu-item\]\:items-center .ant-menu-item {
    align-items: center;
}
.\[\&_\.ant-menu-sub_\>_\.ant-menu-item\]\:flex .ant-menu-sub > .ant-menu-item {
    display: flex;
}
.\[\&_\.ant-menu-sub_\>_\.ant-menu-item\]\:items-center .ant-menu-sub > .ant-menu-item {
    align-items: center;
}
.\[\&_\.ant-menu-submenu-title\]\:flex .ant-menu-submenu-title {
    display: flex;
}
.\[\&_\.ant-menu-submenu-title\]\:items-center .ant-menu-submenu-title {
    align-items: center;
}
.\[\&_\.ant-modal-body\]\:flex-1 .ant-modal-body {
    flex: 1 1 0%;
}
.\[\&_\.ant-modal-body\]\:overflow-auto .ant-modal-body {
    overflow: auto;
}
.\[\&_\.ant-modal-content\]\:flex .ant-modal-content {
    display: flex;
}
.\[\&_\.ant-modal-content\]\:max-h-\[90vh\] .ant-modal-content {
    max-height: 90vh;
}
.\[\&_\.ant-modal-content\]\:flex-col .ant-modal-content {
    flex-direction: column;
}
.\[\&_\.ant-modal-footer\]\:shrink-0 .ant-modal-footer {
    flex-shrink: 0;
}
.\[\&_\.ant-select-item-option-content\]\:flex .ant-select-item-option-content {
    display: flex;
}
.\[\&_\.ant-select-item-option-content\]\:items-center .ant-select-item-option-content {
    align-items: center;
}
.\[\&_\.ant-select-item-option-content\]\:justify-normal .ant-select-item-option-content {
    justify-content: normal;
}
.\[\&_\.ant-select-item-option-content\]\:gap-1 .ant-select-item-option-content {
    gap: 0.25rem;
}
.\[\&_\.ant-select-selection-item\]\:\!flex .ant-select-selection-item {
    display: flex !important;
}
.\[\&_\.ant-select-selection-item\]\:\!items-center .ant-select-selection-item {
    align-items: center !important;
}
.\[\&_\.ant-select-selection-item\]\:\!gap-1 .ant-select-selection-item {
    gap: 0.25rem !important;
}
.\[\&_\.ant-select-tree-checkbox\]\:hidden .ant-select-tree-checkbox {
    display: none;
}
.\[\&_\.ant-select-tree-node-content-wrapper\]\:\!pl-0 .ant-select-tree-node-content-wrapper {
    padding-left: 0px !important;
}
.\[\&_\.ant-select-tree-node-content-wrapper\]\:\!pr-4 .ant-select-tree-node-content-wrapper {
    padding-right: 1rem !important;
}
.\[\&_\.ant-select-tree-switcher-noop\]\:\!hidden .ant-select-tree-switcher-noop {
    display: none !important;
}
.\[\&_\.ant-select-tree-switcher\]\:\!mx-0 .ant-select-tree-switcher {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.\[\&_\.ant-select-tree-treenode-active\]\:\!bg-transparent .ant-select-tree-treenode-active {
    background-color: transparent !important;
}
.\[\&_\.ant-select-tree-treenode-checkbox-checked\>\.ant-select-tree-node-content-wrapper\]\:bg-\[\#F5F7FA\] .ant-select-tree-treenode-checkbox-checked>.ant-select-tree-node-content-wrapper {
    --tw-bg-opacity: 1;
    background-color: rgb(245 247 250 / var(--tw-bg-opacity, 1));
}
.\[\&_\.ant-select-tree-treenode-leaf_\.ant-select-tree-node-content-wrapper\]\:\!pl-0 .ant-select-tree-treenode-leaf .ant-select-tree-node-content-wrapper {
    padding-left: 0px !important;
}
.\[\&_\.ant-select-tree-treenode-leaf_\.ant-select-tree-node-content-wrapper\]\:\!pl-2 .ant-select-tree-treenode-leaf .ant-select-tree-node-content-wrapper {
    padding-left: 0.5rem !important;
}
.\[\&_\.ant-table-row\]\:h-12 .ant-table-row {
    height: 3rem;
}
.\[\&_\.ant-tabs-content\]\:h-full .ant-tabs-content {
    height: 100%;
}
.\[\&_\.ant-tabs-content\]\:w-full .ant-tabs-content {
    width: 100%;
}
.\[\&_\.ant-tabs-content\]\:grow .ant-tabs-content {
    flex-grow: 1;
}
.\[\&_\.ant-tabs-nav\]\:sticky .ant-tabs-nav {
    position: sticky;
}
.\[\&_\.ant-tabs-nav\]\:-top-\[25px\] .ant-tabs-nav {
    top: -25px;
}
.\[\&_\.ant-tabs-nav\]\:z-\[1\] .ant-tabs-nav {
    z-index: 1;
}
.\[\&_\.ant-tabs-nav\]\:bg-white .ant-tabs-nav {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.\[\&_\.ant-tabs\]\:flex .ant-tabs {
    display: flex;
}
.\[\&_\.ant-tabs\]\:h-full .ant-tabs {
    height: 100%;
}
.\[\&_\.ant-tabs\]\:w-full .ant-tabs {
    width: 100%;
}
.\[\&_\.ant-tabs\]\:grow .ant-tabs {
    flex-grow: 1;
}
.\[\&_\.ant-tabs\]\:flex-col .ant-tabs {
    flex-direction: column;
}
.\[\&_\.anticon\]\:mt-1 .anticon {
    margin-top: 0.25rem;
}
.\[\&_\.environment-badges\]\:mr-2 .environment-badges {
    margin-right: 0.5rem;
}
.\[\&_\.message-user-select\]\:pointer-events-none .message-user-select {
    pointer-events: none;
}
.\[\&_\.message-user-select\]\:text-\[red\] .message-user-select {
    --tw-text-opacity: 1;
    color: rgb(255 0 0 / var(--tw-text-opacity, 1));
}
.\[\&_\.playground-property-control-label\]\:\!font-\[500\] .playground-property-control-label {
    font-weight: 500 !important;
}
.\[\&_\.playground-property-control-label\]\:\!capitalize .playground-property-control-label {
    text-transform: capitalize !important;
}
.\[\&_\.playground-property-control\.multi-select-control\]\:\!flex-row .playground-property-control.multi-select-control {
    flex-direction: row !important;
}
.\[\&_\.playground-property-control\.multi-select-control\]\:\!items-center .playground-property-control.multi-select-control {
    align-items: center !important;
}
.\[\&_\.playground-property-control\.multi-select-control\]\:\!justify-between .playground-property-control.multi-select-control {
    justify-content: space-between !important;
}
.\[\&_\.playground-property-control\.multi-select-control_\.ant-select\]\:\!max-w-\[250px\] .playground-property-control.multi-select-control .ant-select {
    max-width: 250px !important;
}
.\[\&_\.playground-property-control\.multi-select-control_\.ant-select\]\:\!grow .playground-property-control.multi-select-control .ant-select {
    flex-grow: 1 !important;
}
.\[\&_\.value1\]\:whitespace-pre .value1 {
    white-space: pre;
}
.\[\&_\.value1\]\:text-wrap .value1 {
    text-wrap: wrap;
}
.\[\&_\>\.ant-form-item\]\:\!mb-0 >.ant-form-item {
    margin-bottom: 0px !important;
}
.\[\&_\>\.ant-select-item-option-content\]\:justify-normal >.ant-select-item-option-content {
    justify-content: normal;
}
.\[\&_\>\.ant-space-item\:nth-child\(1\)\]\:flex-1 >.ant-space-item:nth-child(1) {
    flex: 1 1 0%;
}
.\[\&_\>_div\]\:scale-\[1\.01\] > div {
    --tw-scale-x: 1.01;
    --tw-scale-y: 1.01;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&_article\]\:px-3 article {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.\[\&_article\]\:py-1 article {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.\[\&_input\]\:\!text-center input {
    text-align: center !important;
}
.\[\&_p\:first-child\]\:\!mt-0 p:first-child {
    margin-top: 0px !important;
}
.\[\&_p\:last-child\]\:\!mb-0 p:last-child {
    margin-bottom: 0px !important;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

